import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class AppImageCropperComponent implements OnInit {
    @Input() imageType: EImageType;
    @Input() image?: UserImageModel;
    @Input() isModalVisible: boolean = false;
    @Input() imageChangedEvent: Event;
    @Input() imageBase64: string;
    @Input() inputFile: HTMLInputElement;
    @Output() onUpload = new EventEmitter<UploadedCropperImage>();
    @Output() onClose = new EventEmitter<void>();
    @ViewChild(ImageCropperComponent) cropper: ImageCropperComponent;

    croppedImage: string | null | undefined = '';

    constructor() {}

    ngOnInit(): void {}

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    cropperReady() {}

    loadImageFailed() {}

    handleCancelModal() {
        this.onClose.emit();
        this.croppedImage = null;
        this.imageBase64 = '';
        this.inputFile.value = '';
    }

    handleOkModal() {
        this.cropper.crop();
        const blob = this.dataURItoBlob(this.croppedImage || '');
        const filename = (this.imageChangedEvent.target as any).files[0].name;
        this.onUpload.emit({
            file: new File([blob], filename, {
                type: 'image/jpeg',
            }),
            type: this.imageType,
        });
        this.croppedImage = null;
        this.imageBase64 = '';
        this.onClose.emit();
    }

    public photoAspectRatio(): number {
        let ratio = 1;

        switch (this.imageType) {
            case EImageType.ARENA_AVATAR:
                ratio = 1;
                break;
            case EImageType.ARENA_PHOTO:
                ratio = 1.36;
                break;
            case EImageType.ARENA_PHOTO_BIG:
                ratio = 1.36;
                break;
            case EImageType.FULL_FACE_AVATAR:
                ratio = 1;
                break;
            case EImageType.HALF_LENGTH_AVATAR:
                ratio = 1;
                break;
        }

        return ratio;
    }

    private dataURItoBlob(dataURI: string): Blob {
        const byteString: string = window.atob(dataURI.replace('data:image/jpeg;base64,', ''));
        const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([int8Array], { type: 'image/jpeg' });
    }

    public rotate(type: 'LEFT' | 'RIGHT'): void {
        const processRotate = this.processRotate(this.croppedImage, type === 'RIGHT' ? 90 : -90);
        this.croppedImage = processRotate;
        this.imageBase64 = processRotate;
    }

    public processRotate(srcBase64: string | null | undefined, degrees: number) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        if (srcBase64) {
            image.src = srcBase64;
        }

        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;

        if (ctx) {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((degrees * Math.PI) / 180);
            ctx.drawImage(image, image.width / -2, image.height / -2);
        }

        return canvas.toDataURL();
    }
}
