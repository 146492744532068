import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';
import { GeocodingService } from '@fca-app/services/geocoding.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { debounceTime, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-address-selection',
    templateUrl: './address-selection.component.html',
    styleUrls: ['./address-selection.component.scss'],
})
export class AddressSelectionComponent implements OnInit {
    @Output() onSelect = new EventEmitter<GeocodingModel>();
    data: GeocodingModel[];
    formGroup: FormGroup;

    constructor(private readonly geocodingService: GeocodingService) {}

    public selectAddress(data: GeocodingModel) {
        this.onSelect.emit(data);
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            searchControl: new FormControl(),
        });
        this.formGroup
            .get('searchControl')
            ?.valueChanges.pipe(
                debounceTime(300),
                switchMap(searchValue => this.geocodingService.searchAddress(searchValue)),
                untilDestroy(this)
            )
            .subscribe(foundValues => {
                this.data = foundValues;
            });
    }
}
