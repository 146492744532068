import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { CreateNewUserAbstract } from '@fca-app/dashboard/components/users-management/create-new/create-new-user.abstract';

@Component({
    selector: 'users-create-fan',
    templateUrl: './create-new-fan.component.html',
    styleUrls: ['./create-new-fan.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CreateNewFanComponent extends CreateNewUserAbstract implements OnInit, OnDestroy {
    constructor(protected readonly parentFormDirective: FormGroupDirective) {
        super(parentFormDirective);
    }

    public addControls(): void {
        this.form.addControl('fullname', new FormControl(''));
        this.form.addControl('nickname', new FormControl(''));
        this.form.addControl('firstName', new FormControl('', Validators.required));
        this.form.addControl('lastName', new FormControl('', Validators.required));
    }

    public removeControls(): void {
        this.form.removeControl('fullname');
        this.form.removeControl('nickname');
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
    }

    ngOnDestroy(): void {
        this.removeControls();
    }

    ngOnInit(): void {
        this.addControls();
    }
}
