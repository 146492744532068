import { UserModel, UserModelData } from '@fca-app/models/users/user/user.model';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';

export interface AdminModelData extends UserModelData {
    permissions: EAdminPermissions[];
}

export interface AdminModel extends AdminModelData {}

export class AdminModel extends UserModel {}
