import { FormGroup, FormGroupDirective } from '@angular/forms';

export abstract class CreateNewUserAbstract {
    readonly form: FormGroup;

    protected constructor(protected parentFormDirective: FormGroupDirective) {
        this.form = parentFormDirective.form;
    }

    abstract addControls(): void;
    abstract removeControls(): void;
}
