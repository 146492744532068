<nz-form-item>
  <nz-form-label [nzSpan]="5">Full name</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="fullname" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Nickname</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="nickname" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">First name</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="firstName" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Last name</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="lastName" />
  </nz-form-control>
</nz-form-item>

