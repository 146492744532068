<nz-page-header class="site-page-header">
  <nz-page-header-title>Video metrics</nz-page-header-title>
  <nz-page-header-extra>
    <nz-space>
      <nz-select
        nzMode="default"
        nzPlaceHolder="Select events"
        nzAllowClear
        nzShowSearch
        nzServerSearch
        class="event-select"
        [(ngModel)]="selectedEvent"
        (nzOnSearch)="onSearch($event)"
        (ngModelChange)="emit()"
      >
        <ng-container *ngFor="let o of optionList$ | async">
          <nz-option *ngIf="!isLoading" [nzValue]="o.video" [nzLabel]="o.name"></nz-option>
        </ng-container>
        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
          <span nz-icon nzType="loading" class="loading-icon"></span>
          Loading Data...
        </nz-option>
      </nz-select>
      <nz-range-picker [nzFormat]="dateFormat" [(ngModel)]="date" (ngModelChange)="emit()" [nzAllowClear]="false"></nz-range-picker>
      <nz-select [(ngModel)]="timeStepSelected"
                 (ngModelChange)="emit()"
                 nzPlaceHolder="Time step">
        <nz-option nzValue="1" nzLabel="1 min"></nz-option>
        <nz-option nzValue="15" nzLabel="15 min"></nz-option>
        <nz-option nzValue="60" nzLabel="1 hour"></nz-option>
        <nz-option nzValue="720" nzLabel="12 hours"></nz-option>
        <nz-option nzValue="1440" nzLabel="1 day"></nz-option>
        <nz-option nzValue="10080" nzLabel="1 weak"></nz-option>
        <nz-option nzValue="43200" nzLabel="1 month"></nz-option>
      </nz-select>
    </nz-space>
  </nz-page-header-extra>
</nz-page-header>
<nz-alert style="padding: 10px;"
  nzType="info" nzCloseable
  nzMessage="Informational Notes"
  nzDescription="You can select multiple events at the same time. Collecting metrics take a long time if you specify a large range of dates. The time step also affects the preparation time of reports
        "
  nzShowIcon
></nz-alert>

