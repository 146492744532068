import { IRealtimeVideoMetrics } from '@fca-app/api/video-metrics/video.metrics.interface';
import { RealtimeVideoMetricsModelData } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.model';

export class RealtimeVideoMetricsMapper {
    mapData(raw: IRealtimeVideoMetrics): RealtimeVideoMetricsModelData {
        return {
            mostPopularCountry: raw?.mostPopularCountry ?? '-',
            mostPopularPlatform: raw?.mostPopularPlatform ?? '-',
            mostPopularQuality: raw?.mostPopularQuality ?? '-',
            users: raw.users ?? 0,
        };
    }
}
