import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { CreateFightParams } from '@fca-app/api/fca/fights/interfaces/params/create-fight.params';
import { RemoveFightParams } from '@fca-app/api/fca/fights/interfaces/params/remove-fight.params';
import { UpdateFightWinnerParams } from '@fca-app/api/fca/fights/interfaces/params/update-fight-winner.params';
import { UpdateFightParams } from '@fca-app/api/fca/fights/interfaces/params/update-fight.params';
import { FightApiResponse } from '@fca-app/api/fca/fights/interfaces/response/fight-api.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaFightsApiService extends FcaApiServiceBase {
    updateFightWinner(params: UpdateFightWinnerParams): Observable<any> {
        const url = [this.apiUrl, 'fights', 'update-fight-winner'].join('/');

        return this.http.post(url, params, { headers: this.defaultHeaders }).pipe(publishReplay(1), refCount());
    }

    createFight(params: CreateFightParams): Observable<FightApiResponse> {
        const url = [this.apiUrl, 'fights', 'admin', 'create-fight'].join('/');

        return this.http
            .post<FightApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    deleteFight(params: RemoveFightParams): Observable<void> {
        const url = [this.apiUrl, 'fights', 'delete-fight'].join('/');
        const options = {
            headers: this.defaultHeaders,
            body: params,
        };

        return this.http.delete<void>(url, options).pipe(publishReplay(1), refCount());
    }

    updateFight(params: UpdateFightParams): Observable<FightApiResponse> {
        const url = [this.apiUrl, 'fights', 'admin', 'update-fight'].join('/');

        return this.http.patch<FightApiResponse>(url, params).pipe(publishReplay(1), refCount());
    }
}
