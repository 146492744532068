<nz-layout class="layout">
  <nz-sider [nzTrigger]="null" nzCollapsible>
    <div class="logo"></div>
    <div class="user-info">
      <span class="email" nz-typography>{{ userEmail$ | async }}</span>
    </div>
    <ul nz-menu nzMode="inline" nzTheme="dark">
      <li *ngxPermissionsOnly="['view_arenas', 'view_fighters']" nz-submenu nzIcon="home" nzTitle="Home">
        <ul>
          <li *ngxPermissionsOnly="['view_arenas']" nz-menu-item>
            <a [routerLink]="'arenas'">Arenas</a>
          </li>
          <li *ngxPermissionsOnly="['view_fighters']" nz-menu-item>
            <a [routerLink]="'fighters'">Fighters</a>
          </li>
        </ul>
      </li>
      <li *ngxPermissionsOnly="['create_new_users', 'view_users']" nz-submenu nzIcon="user" nzTitle="Users">
        <ul>
          <li *ngxPermissionsOnly="['create_new_users']" nz-menu-item>
            <a [routerLink]="'users-management/create-user'">Create user</a>
          </li>
          <!--            <li nz-menu-item *ngxPermissionsOnly="['view_users']">-->
          <!--              <a [routerLink]="'users-management/manage'">Manage</a>-->
          <!--            </li>-->
        </ul>
      </li>
      <li *ngxPermissionsOnly="['download_users_statistics']" nz-submenu nzIcon="team" nzTitle="Statistics">
        <ul>
          <li nz-menu-item>
            <a [routerLink]="'statistics/users'">Users</a>
          </li>
          <li nz-menu-item>
            <a [routerLink]="'statistics/videos'">Videos</a>
          </li>
          <li nz-menu-item>
            <a [routerLink]="'statistics/feedbacks'">Feedbacks</a>
          </li>
        </ul>
      </li>
      <li *ngxPermissionsOnly="['download_users_statistics']" nz-submenu nzIcon="build" nzTitle="Matchmaking">
        <ul>
          <li nz-menu-item>
            <a [routerLink]="'matchmaking/manage'">Manage</a>
          </li>
        </ul>
      </li>
      <li *ngxPermissionsOnly="['super_admin']" nz-submenu nzIcon="team" nzTitle="Manage">
        <ul>
          <li nz-menu-item>
            <a [routerLink]="'manage/admins'">Manage admins</a>
          </li>
        </ul>
      </li>
    </ul>
    <button (click)="onLogout()" [nzSize]="'default'" class="logout-btn" nz-button nz-tooltip nzShape="circle"
            nzTooltipTitle="Logout">
      <i class="logout-icon" nz-icon nzTheme="outline" nzType="logout"></i>
    </button>
  </nz-sider>
  <nz-layout class="right-layout">
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
