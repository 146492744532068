<nz-page-header class="title">
  <nz-page-header-title>Matchmaking</nz-page-header-title>
  <nz-page-header-extra>
    <nz-space>
      <button (click)="download()" *nzSpaceItem nz-button>Download</button>
<!--      <button *nzSpaceItem nz-button nzType="primary">Send request from fighter</button>-->
    </nz-space>
  </nz-page-header-extra>
</nz-page-header>

<nz-content class="content">
  <div nz-row>
    <div nz-col [nzSpan]="16" [nzPush]="8">
      <app-matchmaking-list [isLoading$]="isLoading$" [searchResultList$]="searchResultList$"></app-matchmaking-list>
    </div>
    <div nz-col [nzSpan]="8" [nzPull]="16">
      <app-matchmaking-search [isLoading$]="isLoading$" [searchResultList$]="searchResultList$"></app-matchmaking-search>
    </div>
  </div>
</nz-content>
