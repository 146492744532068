import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CreateNewFanComponent } from '@fca-app/dashboard/components/users-management/create-new/create-new-fan/create-new-fan.component';
import { CreateNewFighterComponent } from '@fca-app/dashboard/components/users-management/create-new/create-new-fighter/create-new-fighter.component';
import { CreateNewLocationProviderComponent } from '@fca-app/dashboard/components/users-management/create-new/create-new-location-provider/create-new-location-provider.component';
import { CreateNewUserComponent } from '@fca-app/dashboard/components/users-management/create-new/create-new-user.component';
import { UsersManagementComponent } from '@fca-app/dashboard/components/users-management/users-management.component';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [
        UsersManagementComponent,
        CreateNewUserComponent,
        CreateNewFanComponent,
        CreateNewFighterComponent,
        CreateNewLocationProviderComponent,
    ],
    imports: [FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    exports: [UsersManagementComponent],
    providers: [],
})
export class DashboardUsersManagementModule {}
