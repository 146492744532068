import { UpdateArenaParams } from '@fca-app/api/fca/arenas/interfaces/params/update-arena.params';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';

export class UpdateArenaParamsBuilder {
    build(data: ArenaModel): UpdateArenaParams {
        return {
            roleName: EUserRoleName.LOCATION_PROVIDER,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            location: {
                name: data.location.name,
                address: {
                    formattedAddress: data.location.address.formattedAddress,
                    country: data.location.address.country,
                    city: data.location.address.city,
                    street: data.location.address.street,
                    building: data.location.address.building,
                    latitude: data.location.address.latitude,
                    longitude: data.location.address.latitude,
                },
                link: Boolean(data.location.link) ? data.location.link : null,
                description: Boolean(data.location.description) ? data.location.description : null,
            },
        };
    }
}
