import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { TableViewFightersData } from '@fca-app/dashboard/components/home/fighter-list/interfaces/table-view-fighters-data.interface';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { FighterAccountInfoFactory } from '@fca-app/models/users/fighter/account-info/fighter-account-info.factory';
import { FighterAccountInfoMapper } from '@fca-app/models/users/fighter/account-info/fighter-account-info.mapper';
import { FighterAccountInfoModel } from '@fca-app/models/users/fighter/account-info/fighter-account-info.model';
import { FighterFactory } from '@fca-app/models/users/fighter/fighter.factory';
import { FighterMapper } from '@fca-app/models/users/fighter/fighter.mapper';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { UserModel } from '@fca-app/models/users/user/user.model';
import { CreateUserFormDataParams } from '@fca-app/services/params/users/create/create-user-form-data.params';
import { CreateFanStrategy } from '@fca-app/services/strategies/create-user/create-fan.strategy';
import { CreateFighterStrategy } from '@fca-app/services/strategies/create-user/create-fighter.strategy';
import { CreateLocationProviderStrategy } from '@fca-app/services/strategies/create-user/create-location-provider.strategy';
import { CreateUserAbstractStrategy } from '@fca-app/services/strategies/create-user/create-user-abstract.strategy';
import { EPenalty } from '@fca-app/shared/enums/penalty.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FightersService {
    constructor(private readonly usersService: FcaUsersApiService) {}

    verify(fighterId: number): Observable<void> {
      return this.usersService.verifyUser(fighterId);
    }

    getFighters(
        queryParams: { pageSize: number; pageIndex: number },
        searchQuery?: string
    ): Observable<TableViewFightersData> {
        return this.usersService
            .searchFighters({
                limit: queryParams.pageSize,
                page: queryParams.pageIndex,
                searchTerm: searchQuery,
                roleName: EUserRoleName.FIGHTER,
            })
            .pipe(
                map(response => ({
                    fighters: response.data.map(raw =>
                        new FighterFactory().getModelFromData(new FighterMapper().mapData(raw))
                    ),
                    count: response.count,
                }))
            );
    }

    createNewUser(params: CreateUserFormDataParams): Observable<UserModel | null> {
        const strategiesMap = new Map<string, CreateUserAbstractStrategy>();
        strategiesMap.set(EUserRoleName.FIGHTER, new CreateFighterStrategy(this.usersService));
        strategiesMap.set(EUserRoleName.FAN, new CreateFanStrategy(this.usersService));
        strategiesMap.set(EUserRoleName.LOCATION_PROVIDER, new CreateLocationProviderStrategy(this.usersService));
        const strategy = strategiesMap.get(params.roleName);

        return strategy ? strategy.create(params) : of(null);
    }

    updateFighter(params: { userId: number; rating?: number }): Observable<FighterModel> {
        return this.usersService
            .updateFighter({
                roleName: EUserRoleName.FIGHTER,
                userId: params.userId,
                details: {
                    ranking: params.rating,
                },
            })
            .pipe(map(raw => new FighterFactory().getModelFromData(new FighterMapper().mapData(raw))));
    }

    deletePenalty(id: number): Observable<void> {
        return this.usersService.deletePenalty(id);
    }

    addPenalty(userId: number, penalty: EPenalty, comment: string): Observable<void> {
        return this.usersService.addPenalty({ userId, penalty, comment });
    }

    confirmWithdrawal(fighterId: number): Observable<FighterAccountInfoModel> {
        return this.usersService
            .confirmFighterWithdrawal(fighterId)
            .pipe(
                map(raw =>
                    new FighterAccountInfoFactory().getModelFromData(new FighterAccountInfoMapper().mapData(raw))
                )
            );
    }

    getFighter(id: number): Observable<FighterModel> {
        return combineLatest([this.usersService.getFighterById(id), this.usersService.getFighterAccountInfo(id)]).pipe(
            map(([fighter, accountInfo]) => {
                return new FighterFactory().getModelFromData({
                    ...new FighterMapper().mapData(fighter),
                    account: new FighterAccountInfoFactory().getModelFromData(
                        new FighterAccountInfoMapper().mapData(accountInfo)
                    ),
                });
            })
        );
    }
}
