export interface HistoryVideoMetricsModelData {
    requests: string;
    online: string;
    quality: string;
    country: string;
    totalGb: string;
    avgRequestTime: string;
    maxOnline: string;
    maxDownload: string;
    popularCountry: string;
    popularQuality: string;
    totalUsers: string;
}

export interface HistoryVideoMetricsModel extends HistoryVideoMetricsModelData {}

export class HistoryVideoMetricsModel {}
