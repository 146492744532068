import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { DownloadUsersFeedbacksParams } from '@fca-app/api/fca/feedbacks/interfaces/download-users-feedbacks.params';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaUsersFeedbacksService extends FcaApiServiceBase {
    downloadUsersFeedbacks(params: DownloadUsersFeedbacksParams): Observable<string> {
        const url = [this.apiUrl, 'admin', 'feedbacks', params.activity ? `?activity=${params.activity}` : ''].join(
            '/'
        );

        return this.http
            .get<string>(url, { headers: this.defaultHeaders, responseType: 'blob' as 'json' })
            .pipe(publishReplay(1), refCount());
    }
}
