import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { VideoMetricsApiService } from '@fca-app/api/video-metrics/video-metrics-api.service';
import { ICoords, IGraphMetrics, IMapVideoMetrics } from '@fca-app/api/video-metrics/video.metrics.interface';
import { HistoryVideoMetricsFactory } from '@fca-app/models/video-metrics/history/history-video-metrics.factory';
import { HistoryVideoMetricsMapper } from '@fca-app/models/video-metrics/history/history-video-metrics.mapper';
import { HistoryVideoMetricsModel } from '@fca-app/models/video-metrics/history/history-video-metrics.model';
import { RealtimeVideoMetricsFactory } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.factory';
import { RealtimeVideoMetricsMapper } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.mapper';
import { RealtimeVideoMetricsModel } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.model';
import * as L from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class VideoMetricsService {
    constructor(
        private readonly videoMetricsApiService: VideoMetricsApiService,
        @Inject(LOCALE_ID) private locale: string
    ) {}

    public getHistoryMetrics(data: {
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }): Observable<HistoryVideoMetricsModel> {
        return this.videoMetricsApiService
            .history(data.date[0], data.date[1], data.videoId)
            .pipe(
                map(history =>
                    new HistoryVideoMetricsFactory().getModelFromData(
                        new HistoryVideoMetricsMapper().mapData(history, this.locale)
                    )
                )
            );
    }

    public getMapData(data: {
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }): Observable<IMapVideoMetrics> {
        return this.videoMetricsApiService.map(data.date[0], data.date[1], data.videoId);
    }

    public getLeafletMarkers(response: ICoords[]): L.Marker[] {
        return response
            .filter(key => Boolean(key.lat && key.lat !== 'null'))
            .map(key => {
                const icon = L.icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: '/assets/marker-icon.png',
                    shadowUrl: '/assets/marker-shadow.png',
                });
                const popup = L.popup({ closeButton: false, closeOnClick: true }).setContent(`
                <b>User:</b> ${key.userId}</br>
                <b>Lat:</b> ${key.lat}</br>
                <b>Lon:</b> ${key.lon}
            `);
                return L.marker([Number(key.lat), Number(key.lon)], { icon, title: key.userId }).bindPopup(popup);
            });
    }

    public getRealtimeMetrics(videoId: string | null): Observable<RealtimeVideoMetricsModel> {
        return this.videoMetricsApiService
            .realtime(videoId)
            .pipe(
                map(realtime =>
                    new RealtimeVideoMetricsFactory().getModelFromData(
                        new RealtimeVideoMetricsMapper().mapData(realtime)
                    )
                )
            );
    }

    public getGraphData(data: {
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }): Observable<{ graph: IGraphMetrics[] }> {
        return this.videoMetricsApiService.graph(
            data.date[0],
            data.date[1],
            Number(data.timeStepSelected),
            data.videoId
        );
    }
}
