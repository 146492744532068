import { AccountInfoTransactionItemModel } from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item.model';

export interface FighterAccountInfoModelData {
    balance: number;
    withdrawAvailable: boolean;
    transactions: AccountInfoTransactionItemModel[];
}

export interface FighterAccountInfoModel extends FighterAccountInfoModelData {}

export class FighterAccountInfoModel {}
