import { AdminApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { AdminModelData } from '@fca-app/models/users/admin/admin.model';
import { UserMapper } from '@fca-app/models/users/user/user.mapper';

export class AdminMapper {
    mapData(raw: AdminApiResponse): AdminModelData {
        return {
            ...new UserMapper().mapData(raw),
            permissions: raw.permissions,
        };
    }
}
