import { Injectable } from "@angular/core";
import { FcaArenasApiService } from "@fca-app/api/fca/arenas/fca-arenas-api.service";
import { TableViewArenasData } from "@fca-app/dashboard/components/home/arena-list/interfaces/table-view-arenas-data.interface";
import { EventStatus } from "@fca-app/enums/event-status.enum";
import { EUserRoleName } from "@fca-app/enums/user-role-names.enum";
import { FightEventImageFactory } from "@fca-app/models/images/fight-event-image/fight-event-image.factory";
import { FightEventImageMapper } from "@fca-app/models/images/fight-event-image/fight-event-image.mapper";
import { FightEventImageModel } from "@fca-app/models/images/fight-event-image/fight-event-image.model";
import { ArenaFactory } from "@fca-app/models/users/arena/arena.factory";
import { ArenaMapper } from "@fca-app/models/users/arena/arena.mapper";
import { ArenaModel } from "@fca-app/models/users/arena/arena.model";
import { FightEventFactory } from "@fca-app/models/users/arena/fight-event/fight-event.factory";
import { FightEventMapper } from "@fca-app/models/users/arena/fight-event/fight-event.mapper";
import { FightEventModel } from "@fca-app/models/users/arena/fight-event/fight-event.model";
import { UpdateArenaParamsBuilder } from "@fca-app/services/params/builders/users/update/update-arena-params.builder";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class ArenasService {
    selectedArena$ = new Subject<ArenaModel>();
    needUpdateArenas$ = new Subject<void>();

    constructor(private readonly arenasService: FcaArenasApiService) {}

    uploadFightEventPhoto(fightEventId: string, position: number, file: File): Observable<FightEventImageModel> {
        return this.arenasService
            .uploadFightEventPhoto({
                fightEventId,
                position,
                file,
            })
            .pipe(
                map(resp => new FightEventImageFactory().getModelFromData(new FightEventImageMapper().mapData(resp)))
            );
    }

    searchArenas(
        queryParams: { pageSize: number; pageIndex: number },
        searchQuery?: string
    ): Observable<TableViewArenasData> {
        return this.arenasService
            .searchArenas({
                limit: queryParams.pageSize,
                page: queryParams.pageIndex,
                searchTerm: searchQuery,
                roleName: EUserRoleName.FIGHTER,
            })
            .pipe(
                map(response => ({
                    arenas: response.data.map(raw =>
                        new ArenaFactory().getModelFromData(new ArenaMapper().mapData(raw))
                    ),
                    count: response.count,
                }))
            );
    }

    updateTimeSlots(arena: ArenaModel, fightEvent: FightEventModel): Observable<FightEventModel> {
        return this.arenasService
            .updateTimeSlotsForFightEvent({
                fightEventId: fightEvent.id,
                userId: arena.id,
                timeSlots: fightEvent.fightSlots
                    .map(slot => ({
                        fightId: slot.fightId,
                        isMainCard: slot.fightId ? slot.isMainCard : false,
                    }))
                    .reverse(),
            })
            .pipe(map(raw => new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw))));
    }

    updateFightEvent(fightEvent: FightEventModel): Observable<void> {
        return this.arenasService.updateFightEvent({
            fightEventId: fightEvent.id,
            fromDate: fightEvent.fromDate.valueOf(),
            name: fightEvent.name,
        });
    }

    getArenas(queryParams: NzTableQueryParams): Observable<TableViewArenasData> {
        return this.arenasService
            .getArenas({
                limit: queryParams.pageSize,
                page: queryParams.pageIndex,
            })
            .pipe(
                map(response => ({
                    arenas: response.data.map(raw =>
                        new ArenaFactory().getModelFromData(new ArenaMapper().mapData(raw))
                    ),
                    count: response.count,
                }))
            );
    }

    getArenaById(id: number): Observable<ArenaModel> {
        return this.arenasService
            .getArenaById(id)
            .pipe(map(res => new ArenaFactory().getModelFromData(new ArenaMapper().mapData(res))));
    }

    approveArena(locationId: number): Observable<void> {
        return this.arenasService.approveArena(locationId);
    }

    getArenaEvents(arenaId: number, statuses: EventStatus[]): Observable<FightEventModel[]> {
        return this.arenasService
            .getArenaEvents(arenaId, statuses)
            .pipe(
                map(resp =>
                    resp.map(raw => new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw)))
                )
            );
    }

    updateArenaInfo(arena: ArenaModel): Observable<ArenaModel> {
        const updatedParams = new UpdateArenaParamsBuilder().build(arena);
        return this.arenasService
            .updateArenaInfo(arena.id, updatedParams)
            .pipe(map(raw => new ArenaFactory().getModelFromData(new ArenaMapper().mapData(raw))));
    }
}
