import { LocationAddressApiResponse } from '@fca-app/api/fca/users/interfaces/response/location-address-api.response';
import { LocationAddressModelData } from '@fca-app/models/location-address/location-address.model';

export class LocationAddressMapper {
    mapData(raw: LocationAddressApiResponse): LocationAddressModelData {
        return {
            formattedAddress: raw.formattedAddress,
            country: raw.country,
            city: raw.city,
            street: raw.street,
            building: raw.building,
            latitude: raw.latitude,
            longitude: raw.longitude,
        };
    }
}
