import {
    FightEventImageModel,
    FightEventImageModelData,
} from '@fca-app/models/images/fight-event-image/fight-event-image.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class FightEventImageFactory extends ModelFactory<FightEventImageModelData, FightEventImageModel> {
    protected getInstance(): FightEventImageModel {
        return new FightEventImageModel();
    }
}
