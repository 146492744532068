import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { SlotType } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/enums/slot-type.enum';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FightTimeSlotModel } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { FightersService } from '@fca-app/services/fighters.service';
import { FightsService } from '@fca-app/services/fights.service';
import { EFightWinner } from '@fca-app/shared/enums/fight-winner.enum';
import { untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'arena-event-slot-card',
    styleUrls: ['./arena-event-slot-card.component.scss'],
    templateUrl: './arena-event-slot-card.component.html',
})
export class ArenaEventSlotCardComponent implements OnInit, AfterViewInit {
    readonly placeholder: string;

    @Input() slotType: SlotType;
    @Input() fightEvent: FightEventModel;
    @Input() slot: FightTimeSlotModel;
    @Input() fighter?: FighterModel;
    onRatingChanged$ = new Subject<number>();
    onFightersSearch$ = new Subject<string>();
    foundFighters: FighterModel[] = [];

    constructor(
        private readonly fightersService: FightersService,
        private readonly fightsService: FightsService,
        private readonly appConfigService: AppMainConfigService,
        private readonly nzModalService: NzModalService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.placeholder = appConfigService.getConfig().previewImage;
        this.foundFighters = [];
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.onRatingChanged$
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                switchMap(rating => {
                    return this.fightersService.updateFighter({ userId: this.fighter!.id, rating });
                }),
                untilDestroy(this)
            )
            .subscribe(() => {
                this.nzNotificationService
                    .success('Success', '', { nzDuration: 1500 })
                    .onClick.pipe(take(1), untilDestroy(this))
                    .subscribe();
            });

        this.onFightersSearch$
            .pipe(
                filter(search => search.length >= 3),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(search => this.fightersService.getFighters({ pageIndex: 1, pageSize: 10 }, search)),
                map(result => result.fighters),
                untilDestroy(this)
            )
            .subscribe(fighters => {
                let alreadySelectedFighter: FighterModel | undefined;
                if (this.slot.hasInitiator()) {
                    alreadySelectedFighter = this.slot.fight!.initiator;
                } else if (this.slot.hasTarget()) {
                    alreadySelectedFighter = this.slot.fight!.target;
                }
                this.foundFighters = fighters.filter(f => f.id !== alreadySelectedFighter?.id);
            });
    }

    get isFighterExists(): boolean {
        return Boolean(this.fighter?.id);
    }

    get rating(): number {
        return this.fighter?.details.ranking || 0;
    }

    get isDraw(): boolean {
        return this.slot.fight!.winner === EFightWinner.BOTH;
    }

    get isWinner(): boolean {
        if (!this.slot.fight!.winner) {
            return false;
        }
        let result = false;
        switch (this.slotType) {
            case SlotType.Initiator:
                result = this.slot.fight!.winner === EFightWinner.INITIATOR;
                break;
            case SlotType.Target:
                result = this.slot.fight!.winner === EFightWinner.TARGET;
                break;
        }
        return result;
    }

    onSelectFighter(option: NzAutocompleteOptionComponent): void {
        const selectedFighter = option.nzValue as FighterModel;

        if (this.fightEvent.isFighterExistsInEvent(selectedFighter.id)) {
            this.nzModalService.info({
                nzTitle: 'This fighter already exists in the event',
            });
            return;
        }

        if (this.slotType === SlotType.Initiator) {
            this.slot.fight!.initiator = selectedFighter;
        } else if (this.slotType === SlotType.Target) {
            this.slot.fight!.target = selectedFighter;
        }

        if (this.slot.hasInitiator() && this.slot.hasTarget()) {
            this.fightsService
                .createFight(
                    this.slot.id,
                    this.slot.fight!.initiator.id,
                    this.slot.fight!.target.id,
                    this.fightEvent.id,
                    this.fightEvent.location?.tz
                )
                .pipe(take(1), untilDestroy(this))
                .subscribe(result => {
                    this.slot.fight = {
                        ...result,
                        initiator: this.slot.fight!.initiator,
                        target: this.slot.fight!.target,
                    };
                    this.nzNotificationService
                        .success('Success', '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }

    onFighterSearchChange(value: string): void {
        this.onFightersSearch$.next(value);
    }

    onClickByCard(): void {
        // console.log(this.fight.target.id);
        // console.log(this.fight.initiator.id);
    }

    onRatingChange(rating: number): void {
        this.onRatingChanged$.next(rating);
    }
}
