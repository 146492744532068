import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';

@Injectable()
export class FcaApiServiceBase {
    readonly apiUrl: string;
    readonly defaultHeaders = { 'Content-Type': 'application/json' };

    constructor(protected readonly configService: AppMainConfigService, protected readonly http: HttpClient) {
        this.apiUrl = configService.getConfig().apiUrl;
    }
}
