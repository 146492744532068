import {
    HistoryVideoMetricsModel,
    HistoryVideoMetricsModelData,
} from '@fca-app/models/video-metrics/history/history-video-metrics.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class HistoryVideoMetricsFactory extends ModelFactory<HistoryVideoMetricsModelData, HistoryVideoMetricsModel> {
    protected getInstance(): HistoryVideoMetricsModel {
        return new HistoryVideoMetricsModel();
    }

    public getEmptyModel(): HistoryVideoMetricsModel {
        return this.getModelFromData({
            avgRequestTime: '0',
            country: '-',
            maxDownload: '0',
            maxOnline: '0',
            online: '0',
            popularCountry: '-',
            popularQuality: '-',
            quality: '-',
            requests: '0',
            totalGb: '0',
            totalUsers: '0',
        });
    }
}
