import { EPlatform, EQuality } from '@fca-app/api/video-metrics/video.metrics.interface';

export interface RealtimeVideoMetricsModelData {
    users: number;
    mostPopularPlatform: EPlatform | '-';
    mostPopularQuality: EQuality | '-';
    mostPopularCountry: string;
}

export interface RealtimeVideoMetricsModel extends RealtimeVideoMetricsModelData {}

export class RealtimeVideoMetricsModel {}
