export interface LocationAddressModelData {
    formattedAddress?: string;
    country: string;
    city: string;
    street?: string;
    building?: string;
    latitude?: number;
    longitude?: number;
}

export interface LocationAddressModel extends LocationAddressModelData {}

export class LocationAddressModel {}
