import { AuthData } from '@fca-app/auth/interfaces/tokens-data.interface';
import { Action } from '@ngrx/store';

export enum ActionTypes {
    SAVE_AUTH_DATA = '[FCA.Auth_Data] Save auth tokens',
    CLEAR_AUTH_DATA = '[FCA.Clear_Tokens] Clear auth tokens',
    SAVE_VIEW_ARENAS_PAGE = '[FCA.Save_View_Arenas_Page]',
    SAVE_VIEW_FIGHTERS_PAGE = '[FCA.Save_View_Fighters_Page]',
}

export class SaveAuthDataAction implements Action {
    readonly type = ActionTypes.SAVE_AUTH_DATA;

    constructor(public payload: { data: AuthData }) {}
}

export class ClearAuthDataAction implements Action {
    readonly type = ActionTypes.CLEAR_AUTH_DATA;
}

export class SaveViewArenasPage implements Action {
    readonly type = ActionTypes.SAVE_VIEW_ARENAS_PAGE;

    constructor(public payload: { page: number }) {}
}

export class SaveViewFightersPage implements Action {
    readonly type = ActionTypes.SAVE_VIEW_FIGHTERS_PAGE;

    constructor(public payload: { page: number }) {}
}

export type Actions = SaveAuthDataAction | ClearAuthDataAction | SaveViewArenasPage | SaveViewFightersPage;
