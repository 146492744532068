export type FightEventStatus = 'STARTED' | 'ENDED';
export enum FightEventType {
  FIGHT_EVENT = 'FIGHT_EVENT',
  WEIGHTING = 'WEIGHTING',
  CONFERENCE = 'CONFERENCE',
}

export interface StartStopEventParams {
    status: FightEventStatus;
    type: FightEventType;
    urls: {
        stream: string | null;
        video: string | null;
    };
}
