import { GeocodingResultResponse } from '@fca-app/api/geocoding/interfaces/response/geocoding.response';
import { GeocodingModelData } from '@fca-app/models/geocoding/geocoding.model';

export class GeocodingMapper {
    mapData(raw: GeocodingResultResponse): GeocodingModelData {
        let city = '';
        let country = '';
        let street = '';
        let building = '';

        raw.address_components.forEach(ac => {
            if (ac.types.indexOf('street_number') > -1) {
                building = ac.long_name;
            }
            if (ac.types.indexOf('route') > -1) {
                street = ac.long_name;
            }
            if (ac.types.indexOf('locality') > -1) {
                city = ac.long_name;
            }
            if (ac.types.indexOf('country') > -1) {
                country = ac.long_name;
            }
        });

        return {
            formattedAddress: raw.formatted_address,
            location: {
                lat: raw.geometry.location.lat,
                lng: raw.geometry.location.lng,
            },
            fullAddress: {
                name: raw.formatted_address,
                address: {
                    city,
                    country,
                    building,
                    street,
                },
            },
        };
    }
}
