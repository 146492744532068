import { NgModule } from '@angular/core';
import { ListMatchmakingComponent } from '@fca-app/dashboard/components/matchmaking/list/list-matchmaking.component';
import { MatchmakingDownloadService } from '@fca-app/dashboard/components/matchmaking/matchmaking-download.service';
import { DashboardMatchmakingComponent } from '@fca-app/dashboard/components/matchmaking/matchmaking.component';
import { SearchMatchmakingComponent } from '@fca-app/dashboard/components/matchmaking/search/search-matchmaking.component';
import { SharedModule } from '@fca-app/shared/shared.module';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
    declarations: [DashboardMatchmakingComponent, ListMatchmakingComponent, SearchMatchmakingComponent],
    imports: [NzGridModule, SharedModule],
    providers: [MatchmakingDownloadService],
    exports: [],
})
export class MatchmakingModule {}
