import { ArenaEventConferenceInfo } from "@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response";
import { FightEventConferenceModelData } from "@fca-app/models/users/arena/fight-event/fight-event-conference/fight-event-conference.model";
import { FightEventStartInfoMapper } from "@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.mapper";

export class FightEventConferenceMapper {
  mapData(raw: ArenaEventConferenceInfo): FightEventConferenceModelData {
    return {
      ...new FightEventStartInfoMapper().mapData(raw),
    };
  }
}
