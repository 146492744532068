import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';

@Component({
    selector: 'app-modal-address-selection',
    templateUrl: './modal-geo-address-selection.component.html',
    styleUrls: ['./modal-geo-address-selection.component.scss'],
})
export class ModalGeoAddressSelectionComponent implements OnInit {
    @Input() modalVisible: boolean = false;
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() addressChangedEvent = new EventEmitter<GeocodingModel>();

    ngOnInit(): void {}

    onHandleModalCancel(): void {
        this.cancelEvent.emit();
    }

    onAddressChange(geo: GeocodingModel): void {
        this.addressChangedEvent.emit(geo);
    }
}
