import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArenasModule } from '@fca-app/dashboard/components/home/arena-list/arenas.module';
import { FighterDetailsAccountInfoComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details-account-info/fighter-details-account-info.component';
import { FighterDetailsMatchingComponent } from "@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details-matching/fighter-details-matching.component";
import { FighterDetailsComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details.component';
import { FighterListComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-list.component';
import { FightEventsService } from "@fca-app/services/fight-events.service";
import { MatchmakingService } from "@fca-app/services/matchmaking.service";
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [FighterListComponent, FighterDetailsComponent, FighterDetailsAccountInfoComponent, FighterDetailsMatchingComponent],
    imports: [ArenasModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [FightEventsService, MatchmakingService],
    exports: [],
})
export class DashboardHomeModule {}
