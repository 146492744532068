import {
    LocationAddressModel,
    LocationAddressModelData,
} from '@fca-app/models/location-address/location-address.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class LocationAddressFactory extends ModelFactory<LocationAddressModelData, LocationAddressModel> {
    protected getInstance(): LocationAddressModel {
        return new LocationAddressModel();
    }
}
