import { ArenaEventApiResponse } from "@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response";
import { FighterApiResponse } from "@fca-app/api/fca/users/interfaces/response/fighter/fighter-api.response";

export enum EMatchingStatus {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  MATCHED = 'MATCHED',
}

export interface FighterMatchmakingRequestApiResponse {
  id: number;
  status: EMatchingStatus;
  fighterId: number;
  eventId: string;
  event: ArenaEventApiResponse;
  fighter?: FighterApiResponse;
}
