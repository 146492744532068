import { FighterMatchmakingRequestApiResponse } from "@fca-app/api/fca/matchmaking/interfaces/response/fighter-matchmaking-request-api.response";
import { MatchmakingRequestModelData } from "@fca-app/models/matchmaking/matchmaking-request.model";
import { FightEventFactory } from "@fca-app/models/users/arena/fight-event/fight-event.factory";
import { FightEventMapper } from "@fca-app/models/users/arena/fight-event/fight-event.mapper";
import { FighterFactory } from "@fca-app/models/users/fighter/fighter.factory";
import { FighterMapper } from "@fca-app/models/users/fighter/fighter.mapper";

export class MatchmakingRequestMapper {
  mapData(raw: FighterMatchmakingRequestApiResponse): MatchmakingRequestModelData {
    return {
      id: raw.id,
      status: raw.status,
      fighterId: raw.fighterId,
      eventId: raw.eventId,
      ...(raw.fighter && {
        fighter: new FighterFactory().getModelFromData(
          new FighterMapper().mapData(raw.fighter)
        )
      }),
      event: new FightEventFactory().getModelFromData(
        new FightEventMapper().mapData(raw.event)
      )
    };
  }
}
