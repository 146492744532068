import { LocationProviderApiResponse } from '@fca-app/api/fca/users/interfaces/response/location-provider-api.response';
import { ArenaModelData } from '@fca-app/models/users/arena/arena.model';
import { UserImageFactory } from '@fca-app/models/images/user-image/user-image.factory';
import { UserImageMapper } from '@fca-app/models/images/user-image/user-image.mapper';
import { LocationFactory } from '@fca-app/models/location/location.factory';
import { LocationMapper } from '@fca-app/models/location/location.mapper';
import { UserMapper } from '@fca-app/models/users/user/user.mapper';

export class ArenaMapper {
    mapData(raw: LocationProviderApiResponse): ArenaModelData {
        return {
            ...new UserMapper().mapData(raw),
            firstName: raw.firstName || '',
            lastName: raw.lastName || '',
            createDate: raw.createDate,
            fullName: `${raw.firstName || ''}${!raw.firstName ? '' : ' '}${raw.lastName || ''}`,
            location: new LocationFactory().getModelFromData(new LocationMapper().mapData(raw.location)),
            images: raw.images.map(rawImg =>
                new UserImageFactory().getModelFromData(new UserImageMapper().mapData(rawImg))
            ),
        };
    }
}
