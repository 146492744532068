import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppMainConfigService } from "@fca-app/config/app-main-config.service";
import { FighterModel } from "@fca-app/models/users/fighter/fighter.model";
import { FightEventsService } from "@fca-app/services/fight-events.service";
import { FightersService } from "@fca-app/services/fighters.service";
import { EAdminPermissions } from "@fca-app/shared/enums/admin-permissions.enum";
import { EPenalty } from "@fca-app/shared/enums/penalty.enum";
import { UntilDestroy, untilDestroy } from "@fca-app/shared/operator/until-destroy.operator";
import { NzModalService } from "ng-zorro-antd/modal";
import { combineLatest, Subject } from "rxjs";
import { startWith, switchMap, take } from "rxjs/operators";

const countPenaltiesForBlock = 3;
type TimelineColor = "red" | "blue";

@UntilDestroy()
@Component({
  selector: "app-dashboard-fighter-details",
  styleUrls: ["./fighter-details.component.scss"],
  templateUrl: "./fighter-details.component.html"
})
export class FighterDetailsComponent {
  timelineItems: { title: string; color: TimelineColor; value: string }[] = [];
  fighter: FighterModel;
  previewImage: string;
  addPenaltyModalVisible = false;
  selectedComment = "Select a reason";
  selectedPenalty: EPenalty = EPenalty.MISS_FIGHT;
  inputCommentValue = "";
  penalties = Object.values(EPenalty);
  comments = [
    "I have a physical condition (injury)",
    "I have no travel possibilities to tournament location",
    "I want too challenge other features of the platform",
    `I don't to fight`,
    "Opponent has already fought me",
    "Opponent seems to be fake or scam",
    "Other"
  ];
  permissions = EAdminPermissions;
  reloadFighter$ = new Subject<void>();

  constructor(
    private readonly appConfigService: AppMainConfigService,
    private readonly fightersService: FightersService,
    private readonly modal: NzModalService,
    private readonly fightEventsService: FightEventsService,
    private route: ActivatedRoute
  ) {
    this.previewImage = appConfigService.getConfig().previewImage;
    combineLatest([this.route.params, this.reloadFighter$.asObservable().pipe(startWith(null))])
      .pipe(
        switchMap(([params]) => {
          const id = Number(params["id"]);
          return this.fightersService.getFighter(id);
        }),
        untilDestroy(this)
      )
      .subscribe(fighter => {
        this.fighter = fighter;
        this.timelineItems = [
          {
            title: "Organization contract",
            color: this.getTimelineColor(fighter.profileFillingInfo.hasSignedContractWithOrganization),
            value: this.booleanToString(fighter.profileFillingInfo.hasSignedContractWithOrganization)
          },
          {
            title: "Phone",
            color: this.getTimelineColor(fighter.profileFillingInfo.hasPhone),
            value: this.booleanToString(fighter.profileFillingInfo.hasPhone)
          },
          {
            title: "Full address",
            color: this.getTimelineColor(fighter.profileFillingInfo.hasFullAddress),
            value: this.booleanToString(fighter.profileFillingInfo.hasFullAddress)
          },
          {
            title: "Billing info",
            color: this.getTimelineColor(fighter.profileFillingInfo.hasBillingInfo),
            value: this.booleanToString(fighter.profileFillingInfo.hasBillingInfo)
          }
        ];
      });
  }

  getTimelineColor(value: boolean): TimelineColor {
    return value ? "blue" : "red";
  }

  booleanToString(value: boolean): string {
    return value ? "Yes" : "No";
  }

  handleCancel(): void {
    this.addPenaltyModalVisible = false;
  }

  onAddPenaltyClick(): void {
    this.addPenaltyModalVisible = true;
  }

  onCommentSelect(comment: string): void {
    this.selectedComment = comment;
  }

  onPenaltySelect(penalty: EPenalty): void {
    this.selectedPenalty = penalty;
  }

  onVerifyClick(): void {
    this.fightersService.verify(this.fighter.id).pipe(untilDestroy(this), take(1)).subscribe(() => {
      this.fighter.verified = true;
    });
  }

  onSavePenaltyClick(): void {
    this.addPenaltyModalVisible = false;
    const comment = this.selectedComment === "Other" ? this.inputCommentValue : this.selectedComment;
    this.fightersService
      .addPenalty(this.fighter!.id, this.selectedPenalty, comment)
      .pipe(take(1), untilDestroy(this))
      .subscribe(() => {
        this.reloadFighter$.next();
      });
  }

  onDeletePenalty(id: number): void {
    if (this.fighter!.penalties.length === countPenaltiesForBlock) {
      this.modal.confirm({
        nzTitle: "Are you sure delete this penalty(user will be unlocked)?",
        nzContent: `This user is banned because he has ${countPenaltiesForBlock} penalties`,
        nzOkText: "Agree",
        nzOkType: "primary",
        nzOkDanger: true,
        nzOnOk: () => this.deletePenalty(id),
        nzCancelText: "No"
      });
    } else {
      this.deletePenalty(id);
    }
  }

  getPenaltyPreview(penalty: EPenalty): string {
    let text = "";
    switch (penalty) {
      case EPenalty.FIXED_FIGHT:
        text = "Fixed fight";
        break;
      case EPenalty.MISS_FIGHT:
        text = "Miss fight";
        break;
      case EPenalty.VIOLATION_FIGHT_RULES:
        text = "Violation of the fight rules";
        break;
      case EPenalty.VIOLATION_WEIGHTING_RULES:
        text = "Violation of the weighting rules";
        break;
    }

    return text;
  }

  private deletePenalty(id: number): void {
    this.fightersService
      .deletePenalty(id)
      .pipe(take(1), untilDestroy(this))
      .subscribe(() => {
        this.fighter!.penalties = this.fighter!.penalties.filter(p => p.id !== id);
        this.reloadFighter$.next();
      });
  }
}
