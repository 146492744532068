import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { UserModel } from '@fca-app/models/users/user/user.model';
import { CreateUserFormDataParams } from '@fca-app/services/params/users/create/create-user-form-data.params';
import { Observable } from 'rxjs';

export abstract class CreateUserAbstractStrategy {
    protected constructor(protected usersService: FcaUsersApiService) {}

    abstract create(data: CreateUserFormDataParams): Observable<UserModel>;
}
