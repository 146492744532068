import { CreateUserParams } from '@fca-app/api/fca/users/interfaces/params/create/create-user.params';
import { CreateUserFormDataParams } from '@fca-app/services/params/users/create/create-user-form-data.params';

export abstract class CreateUserBaseBuilder {
    build(data: CreateUserFormDataParams): CreateUserParams {
        return {
            roleName: data.roleName,
            // phoneNumber: data.phone,
            email: data.email,
            deviceToken: 'deviceToken11',
            deviceIdentifier: 'deviceIdentifier11',
            deviceName: 'deviceName11',
            registrationToken: 'registrationToken11',
        };
    }
}
