import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { ArenaFactory } from '@fca-app/models/users/arena/arena.factory';
import { ArenaMapper } from '@fca-app/models/users/arena/arena.mapper';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';
import { CreateArenaParamsBuilder } from '@fca-app/services/params/builders/users/create/create-arena-params.builder';
import { CreateArenaFormDataParams } from '@fca-app/services/params/users/create/create-arena-form-data.params';
import { CreateUserAbstractStrategy } from '@fca-app/services/strategies/create-user/create-user-abstract.strategy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CreateLocationProviderStrategy extends CreateUserAbstractStrategy {
    constructor(protected usersService: FcaUsersApiService) {
        super(usersService);
    }

    create(data: CreateArenaFormDataParams): Observable<ArenaModel> {
        const params = new CreateArenaParamsBuilder().build(data);

        return this.usersService
            .createLocationProvider(params)
            .pipe(map(raw => new ArenaFactory().getModelFromData(new ArenaMapper().mapData(raw))));
    }
}
