export enum EVideoStatus {
    PENDING = 'PENDING',
    LIVE = 'LIVE',
    FINISHED = 'FINISHED',
}

export interface VideoApiResponse {
    id: number;
    name: string;
    status: EVideoStatus;
    streamUrl: string | null;
    videoUrl: string | null;
}
