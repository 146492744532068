import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { LocationModel } from '@fca-app/models/location/location.model';
import { UserModel, UserModelData } from '@fca-app/models/users/user/user.model';

export interface ArenaModelData extends UserModelData {
    firstName: string;
    lastName: string;
    location: LocationModel;
    images: UserImageModel[];
    fullName: string;
    createDate: Date;
}

export interface ArenaModel extends ArenaModelData {}

export class ArenaModel extends UserModel {}
