import { CreateLocationProviderParams } from '@fca-app/api/fca/users/interfaces/params/create/create-location-provider.params';
import { CreateUserBaseBuilder } from '@fca-app/services/params/builders/users/create/create-user-base.builder';
import { CreateArenaFormDataParams } from '@fca-app/services/params/users/create/create-arena-form-data.params';

export class CreateArenaParamsBuilder extends CreateUserBaseBuilder {
    build(data: CreateArenaFormDataParams): CreateLocationProviderParams {
        return {
            ...super.build(data),
            firstName: data.firstName,
            lastName: data.lastName,
            location: {
                name: data.name,
                address: {
                    ...(data.location.formattedAddress && {
                        formattedAddress: data.location.formattedAddress,
                    }),
                    country: data.location.fullAddress.address.country,
                    city: data.location.fullAddress.address.city,
                    ...(data.location.fullAddress.address.street && {
                        street: data.location.fullAddress.address.street,
                    }),
                    ...(data.location.fullAddress.address.building && {
                        building: data.location.fullAddress.address.building,
                    }),
                    latitude: Number(data.location.location.lat),
                    longitude: Number(data.location.location.lng),
                },
                ...(data.link && { link: data.link }),
                ...(data.description && { description: data.description }),
            },
        };
    }
}
