<app-modal-address-selection [modalVisible]='isModalLocationVisible'
                             (addressChangedEvent)='onAddressChange($event)'
                             (cancelEvent)='handleModalCancel()'
></app-modal-address-selection>
<nz-form-item>
  <nz-form-label [nzSpan]="5">Location</nz-form-label>
  <nz-form-control [nzSpan]="12">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" [value]='selectedGeo?.formattedAddress || ""' (keydown)='onKeyDownLocation($event)' nz-input placeholder="Tap this field" (click)='openLocationSelection()'/>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="global"></i>
    </ng-template>
  </nz-form-control>
</nz-form-item>
<nz-form-item>
  <nz-form-label [nzSpan]="5">First name</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="firstName" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Last name</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="lastName" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Gender</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menuGenders">
    {{ selectedGender }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menuGenders="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let gender of genders" (click)="onSelectGender(gender)">{{ gender }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Birthdate</nz-form-label>
  <nz-form-control [nzSpan]="12">
    <nz-date-picker formControlName="birthdate"></nz-date-picker>
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Unit system</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menu2">
    {{ selectedUnitSystem }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menu2="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let us of unitSystems" (click)="onSelectUnitSystem(us)">{{ us }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Locale</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menuLocales">
    {{ selectedLocale }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menuLocales="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let loc of locales" (click)="onSelectLocale(loc)">{{ loc }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Nickname</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="nickname" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Coaches</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="coaches" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Club</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="club" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Height</nz-form-label>
  <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
    <input nz-input formControlName="height" />
  </nz-form-control>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Weight</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menuWeights">
    {{ selectedWeight }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menuWeights="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let weight of weights" (click)="onSelectWeight(weight)">{{ weight }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Experience</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menuExperiences">
    {{ selectedExperience }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menuExperiences="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let exp of experiences" (click)="onSelectExperience(exp)">{{ exp }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>

<nz-form-item>
  <nz-form-label [nzSpan]="5">Search distance</nz-form-label>
  <button nz-button nz-dropdown [nzDropdownMenu]="menuSearchDistances">
    {{ selectedSearchDistance }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menuSearchDistances="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let sDist of searchDistances" (click)="onSelectSearchDistance(sDist)">{{ sDist }}</li>
    </ul>
  </nz-dropdown-menu>
</nz-form-item>
