import { ImageApiResponse, UserImageApiResponse } from '@fca-app/api/fca/images/interfaces/response/image-api.response';
import { ImageMapper } from '@fca-app/models/images/image.mapper';
import { UserImageModelData } from '@fca-app/models/images/user-image/user-image.model';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';

export class UserImageMapper {
    mapData(raw: UserImageApiResponse): UserImageModelData {
        return {
            ...new ImageMapper().mapData(raw),
            imageType: raw.imageType as EImageType,
            userId: raw.userId,
        };
    }
}
