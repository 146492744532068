<div style="padding-left: 10px; padding-right: 10px; padding-bottom: 10px; margin-bottom: 10px; background-color: #e6f7ff00;">
  <div style="display: flex; padding-top: 10px;">
    <h4>Realtime</h4>
    <span nz-icon [nzType]="'loading'" style="margin-left: 10px; font-size: 25px; color: #52c41a;"></span>
  </div>
  <nz-row [nzGutter]="12">
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 12, offset: 0 }"
      [nzLg]="{ span: 8, offset: 0 }"
      [nzXl]="{ span: 6, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(realtime$ | async)?.users"
          [nzTitle]="'Online'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 12, offset: 0 }"
      [nzLg]="{ span: 8, offset: 0 }"
      [nzXl]="{ span: 6, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(realtime$ | async)?.mostPopularPlatform"
          [nzTitle]="'Popular platform'"
          [nzPrefix]="prefixTpl"
        ></nz-statistic>
        <ng-template #prefixTpl>
          <span *ngIf="(realtime$ | async)?.mostPopularPlatform === 'iOS'" style="display: inline-flex;" nz-icon nzType="apple" nzTheme="outline"></span>
          <span *ngIf="(realtime$ | async)?.mostPopularPlatform === 'Android'" style="display: inline-flex;" nz-icon nzType="android" nzTheme="outline"></span>
        </ng-template>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 12, offset: 0 }"
      [nzLg]="{ span: 8, offset: 0 }"
      [nzXl]="{ span: 6, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(realtime$ | async)?.mostPopularQuality"
          [nzTitle]="'Popular quality'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 12, offset: 0 }"
      [nzLg]="{ span: 8, offset: 0 }"
      [nzXl]="{ span: 6, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(realtime$ | async)?.mostPopularCountry"
          [nzTitle]="'Popular country'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
  </nz-row>
</div>
