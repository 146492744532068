export function countDaysUpToMonth(month: number) {
    const year = new Date().getFullYear();
    let days = 0;

    for (let i = 1; i <= month; i++) {
        days += new Date(year, i, 0).getDate();
    }

    return days;
}
