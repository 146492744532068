import {
    FighterAccountInfoModel,
    FighterAccountInfoModelData,
} from '@fca-app/models/users/fighter/account-info/fighter-account-info.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class FighterAccountInfoFactory extends ModelFactory<FighterAccountInfoModelData, FighterAccountInfoModel> {
    protected getInstance(): FighterAccountInfoModel {
        return new FighterAccountInfoModel();
    }
}
