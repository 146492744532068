import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { StartStopEventParams } from '@fca-app/api/fca/web-hooks/interfaces/params/start-stop-event.params';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaWebHooksApiService extends FcaApiServiceBase {
    startStopEvent(fightEventId: string, params: StartStopEventParams): Observable<void> {
        const authKey = this.configService.getConfig().videoStreamingAuthKey;
        const headers = new HttpHeaders({
            Authorization: `Bearer ${authKey}`,
        });
        const url = [this.apiUrl.replace('/api/v1', ''), 'web-hooks', 'events', fightEventId].join('/');

        return this.http
            .patch<void>(url, params, { headers })
            .pipe(publishReplay(1), refCount());
    }
}
