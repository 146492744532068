import {
  FightEventStartInfoModel,
  FightEventStartInfoModelData
} from "@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.model";

export interface FightEventWeightingModelData extends FightEventStartInfoModelData {}

export interface FightEventWeightingModel extends FightEventWeightingModelData {}

export class FightEventWeightingModel extends FightEventStartInfoModel {}
