import { UserApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { UserModelData } from '@fca-app/models/users/user/user.model';

export class UserMapper {
    mapData(raw: UserApiResponse): UserModelData {
        return {
            id: raw.id,
            phoneNumber: raw.phoneNumber,
            email: raw.email,
            role: raw.roleName as EUserRoleName,
        };
    }
}
