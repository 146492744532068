import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SlotType } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/enums/slot-type.enum';

@Component({
    selector: 'arena-event-slot-empty',
    styleUrls: ['./arena-event-slot-empty.component.scss'],
    templateUrl: './arena-event-slot-empty.component.html',
})
export class ArenaEventSlotEmptyComponent {
    @Input() slotType: SlotType;
    @Output() addCardClick = new EventEmitter<SlotType>();

    onAddClick(): void {
        this.addCardClick.emit(this.slotType);
    }
}
