import { Component, Input } from '@angular/core';
import {
    ICsvMatchmaking,
    MatchmakingDownloadService,
} from '@fca-app/dashboard/components/matchmaking/matchmaking-download.service';
import { MatchmakingRequestModel } from "@fca-app/models/matchmaking/matchmaking-request.model";
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-matchmaking',
    templateUrl: './matchmaking.component.html',
    styleUrls: ['./matchmaking.component.scss'],
})
export class DashboardMatchmakingComponent {
    @Input() isLoading$ = new BehaviorSubject<boolean>(false);
    searchResultList$ = new BehaviorSubject<MatchmakingRequestModel[]>([]);

    constructor(private readonly matchmakingDownloadService: MatchmakingDownloadService) {}

    private prepareData(data: any[]): ICsvMatchmaking[] {
        return data.map(key => ({
            id: `${String(key.fighter.id).replace(',', ' - ')}`,
            status: `${String(key.status).replace(',', ' - ')}`,
            event: `${String(key.event.name).replace(',', ' - ')}`,
            name: `${String(key.fighter.firstName).replace(',', ' - ')}`,
            surname: `${String(key.fighter.lastName).replace(',', ' - ')}`,
            nickname: `${String(key.fighter.nickname).replace(',', ' - ')}`,
            email: `${String(key.fighter.email).replace(',', ' - ')}`,
            mobile: `${String(key.fighter.phone).replace(',', ' - ')}`,
            location: `${String(key.fighter.residence.city).replace(',', ' - ')} ${String(
                key.fighter.residence.country
            ).replace(',', ' - ')}`,
            camp: `${String(key.fighter.details.club).replace(',', ' - ').replace(',', ' - ')}`,
            coach: `${String(key.fighter.details.coaches).replace(',', ' - ').replace(',', ' - ')}`,
            age: `${String('string').replace(',', ' - ')}`,
            gender: `${String(key.fighter.details.gender).replace(',', ' - ')}`,
            weighting: `${String(key.fighter.details.weight).replace(',', ' - ')}`,
        }));
    }

    public download(): void {
        const data = this.searchResultList$.getValue();
        this.matchmakingDownloadService.downloadFile(this.prepareData(data), 'data');
    }
}
