import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { FightersService } from '@fca-app/services/fighters.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-dashboard-users-create',
    templateUrl: './create-new-user.component.html',
    styleUrls: ['./create-new-user.component.scss'],
})
export class CreateNewUserComponent implements OnInit {
    readonly availableRoles = EUserRoleName;

    selectedRole: string | null = null;
    form: FormGroup;

    constructor(
        private readonly fightersService: FightersService,
        private readonly notificationService: NzNotificationService
    ) {}

    ngOnInit(): void {
        this.selectedRole = EUserRoleName.FIGHTER;
        this.initForm();
    }

    public get roles(): string[] {
        const excludedRoles = [EUserRoleName.ADMIN];
        return Object.values(EUserRoleName).filter(r => !excludedRoles.includes(r)) as string[];
    }

    public onSelectRole(role: string): void {
        this.resetForm();
        this.selectedRole = role;
        this.form.get('roleName')?.patchValue(role);
    }

    public onCreate(): void {
        if (this.form.valid) {
            this.fightersService
                .createNewUser(this.form.value)
                .pipe(take(1), untilDestroy(this))
                .subscribe(() => {
                    this.resetForm();
                    this.notificationService
                        .success('Success', '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }

    public resetForm(): void {
        this.form.reset({});
    }

    private initForm(): void {
        this.form = new FormGroup({
            roleName: new FormControl(this.selectedRole, Validators.required),
            // phone: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }
}
