import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { AdminFactory } from '@fca-app/models/users/admin/admin.factory';
import { AdminMapper } from '@fca-app/models/users/admin/admin.mapper';
import { AdminModel } from '@fca-app/models/users/admin/admin.model';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AdminsService {
    constructor(private readonly usersService: FcaUsersApiService, public readonly preloadService: FcaPreloadService) {}

    getAdminsList(): Observable<AdminModel[]> {
        this.preloadService.preload(true);
        return this.usersService.getAdminsList().pipe(
            tap(() => this.preloadService.preload(false)),
            map(resp => resp.map(raw => new AdminFactory().getModelFromData(new AdminMapper().mapData(raw))))
        );
    }

    updateAdmin(id: number, permissions: EAdminPermissions[]): Observable<void> {
        return this.usersService.updateAdmin({ userId: id, permissions });
    }
}
