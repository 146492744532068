import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FcaUsersStatisticsService } from '@fca-app/api/fca/statistics/fca-users-statistics.service';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, mergeMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'title-statistics-video',
    templateUrl: './title-statistics-video.component.html',
    styleUrls: ['./title-statistics-video.component.scss'],
})
export class TitleStatisticsVideoComponent implements OnInit, AfterViewInit {
    searchChange$ = new BehaviorSubject('');
    selectedEvent?: string | null;
    isLoading = false;

    dateFormat = 'yyyy/MM/dd';
    monthFormat = 'yyyy/MM';
    timeStepSelected = '720';
    date: Date[] = [];

    public optionList$: Observable<{ name: string; video: string }[] | null> = this.searchChange$.asObservable().pipe(
        debounceTime(500),
        mergeMap(data => this.fcaUsersStatisticsService.getFightEvents(data).pipe(tap(_ => (this.isLoading = false))))
    );
    @Output()
    private outputEventEmitter = new EventEmitter<{
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }>();

    constructor(private fcaUsersStatisticsService: FcaUsersStatisticsService) {}

    public onSearch(value: string): void {
        this.isLoading = true;
        this.searchChange$.next(value);
    }

    ngOnInit(): void {
        const [lastDate, nextDate] = [new Date(), new Date()];
        this.date = [new Date(lastDate.setMonth(lastDate.getUTCMonth() - 1)), new Date(nextDate)];
    }

    public emit(): void {
        this.outputEventEmitter.emit({
            date: [this.date[0].valueOf(), this.date[1].valueOf()],
            timeStepSelected: this.timeStepSelected,
            videoId: this.selectedEvent ?? null,
        });
    }

    ngAfterViewInit(): void {
        this.emit();
    }
}
