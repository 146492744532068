import { Injectable } from '@angular/core';
import { FcaAuthApiService } from '@fca-app/api/fca/auth/fca-auth-api.service';
import { LoginResponse } from '@fca-app/api/fca/auth/interfaces/response/login.response';
import { FcaEmailApiService } from '@fca-app/api/fca/email/fca-email-api.service';
import { AdminApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { AdminFactory } from '@fca-app/models/users/admin/admin.factory';
import { AdminMapper } from '@fca-app/models/users/admin/admin.mapper';
import { AdminModel } from '@fca-app/models/users/admin/admin.model';
import {
    ClearAuthDataAction,
    SaveAuthDataAction,
    SaveViewArenasPage,
    SaveViewFightersPage,
} from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { RefreshTokenResponse } from '../../api/fca/auth/interfaces/response/refresh-token.response';
import { CheckUserParams } from '../../api/fca/email/interfaces/params/check-user.params';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private accessKeySubject = new BehaviorSubject<string | null>(null);
    readonly currentUser$: Observable<AdminModel | undefined>;

    constructor(
        private readonly smsService: FcaEmailApiService,
        private readonly authService: FcaAuthApiService,
        private readonly storeService: FcaStoreService,
        private readonly socialAuthService: SocialAuthService,
        private readonly permissionsService: NgxPermissionsService
    ) {
        this.currentUser$ = this.accessKeySubject.pipe(
            switchMap(() => this.fetchCurrentUserFromServer()),
            catchError(err => {
                console.error(err);
                return of(undefined);
            })
        );
    }

    public sendSMS(email: string): Observable<void> {
        return this.smsService.sendSMS({ email }).pipe(take(1));
    }

    public checkUser(checkUserDto: CheckUserParams): Observable<string> {
        return this.smsService.checkUser(checkUserDto).pipe(
            take(1),
            map(resp => resp.registrationToken)
        );
    }

    googleLogin(): Observable<any> {
        return from(this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)).pipe(
            switchMap(user => {
                return this.authService
                    .googleSignIn(user.response.access_token, {
                        deviceToken: 'deviceToken11',
                        deviceIdentifier: 'deviceIdentifier11',
                        deviceName: 'deviceName11',
                        email: user.email,
                    })
                    .pipe(tap((loginResponse: LoginResponse) => this.actionsAfterLogin(loginResponse)));
            })
        );
    }

    public login(regToken: string, email: string): Observable<LoginResponse> {
        return this.authService
            .login({
                registrationToken: regToken,
                email,
                deviceToken: 'deviceToken11',
                deviceIdentifier: 'deviceIdentifier11',
                deviceName: 'deviceName11',
            })
            .pipe(
                take(1),
                tap((loginResponse: LoginResponse) => this.actionsAfterLogin(loginResponse))
            );
    }

    public logout(): void {
        this.clearData();
        this.permissionsService.flushPermissions();
        this.accessKeySubject.next(null);
    }

    public fetchCurrentUserFromServer(): Observable<AdminModel> {
        return this.authService.getAuthUser().pipe(
            take(1),
            tap(data => {
                this.permissionsService.flushPermissions();
                this.permissionsService.loadPermissions(data.permissions);
            }),
            map((authUser: AdminApiResponse) =>
                new AdminFactory().getModelFromData(new AdminMapper().mapData(authUser))
            )
        );
    }

    public getNewTokens(): Observable<RefreshTokenResponse> {
        return this.storeService.getAuthData().pipe(
            switchMap(tokens => {
                return this.authService.generateNewTokens(tokens?.refreshToken || '');
            })
        );
    }

    private clearData(): void {
        this.storeService.dispatch(new ClearAuthDataAction());
        this.storeService.dispatch(new SaveViewArenasPage({ page: 1 }));
        this.storeService.dispatch(new SaveViewFightersPage({ page: 1 }));
    }

    private actionsAfterLogin(loginResponse: LoginResponse): void {
        this.storeService.dispatch(
            new SaveAuthDataAction({
                data: {
                    jwt: loginResponse.jwt,
                    refreshToken: loginResponse.refreshToken,
                    email: loginResponse.email,
                    permissions: loginResponse.permissions,
                },
            })
        );
        this.accessKeySubject.next(loginResponse.jwt);
        this.permissionsService.loadPermissions(loginResponse.permissions);
    }
}
