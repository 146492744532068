import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';

@Component({
    selector: 'app-arena-contact',
    styleUrls: ['./arena-contact.component.scss'],
    templateUrl: './arena-contact.component.html',
})
export class ArenaContactComponent {
    @Input() data: ArenaModel;
    @Output() change = new EventEmitter<void>();

    constructor() {}

    onChange(): void {
        this.change.emit();
    }
}
