import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { WINDOW } from '@fca-app/shared/inject/window-injection';
import { GoogleApiInterceptor } from '@fca-app/shared/interceptors/google-api.interceptor';
import { StoreModule } from '@ngrx/store';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppMainConfigModule } from './config/app-main-config.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { FcaStoreModule } from './store/store.module';

registerLocaleData(en);

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppMainConfigModule.forRoot(),
        StoreModule.forRoot({}),
        FcaStoreModule,
        BrowserModule,
        ApiModule,
        DashboardModule,
        AuthModule,
        AppRoutingModule,
        SharedModule,
        NzButtonModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        LeafletModule,
        NgxChartsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GoogleApiInterceptor,
            multi: true,
        },
        {
            provide: NZ_I18N,
            useValue: en_US,
        },
        {
            provide: WINDOW,
            useValue: window,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
