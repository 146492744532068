import { Injectable } from "@angular/core";
import { ArenaEventApiResponse } from "@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response";
import { FcaApiServiceBase } from "@fca-app/api/fca/fca-api-service-base.service";
import { Observable } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";

@Injectable()
export class FcaFightEventsApiService extends FcaApiServiceBase {
  getFightEvent(id: string): Observable<ArenaEventApiResponse> {
    const url = [this.apiUrl, 'fight-events', id].join('/');
    return this.http.get<ArenaEventApiResponse>(url, { headers: this.defaultHeaders }).pipe(publishReplay(1), refCount());
  }
}
