import { ImageModel, ImageModelData } from '@fca-app/models/images/image.model';
import { EImageType } from '../../../shared/enums/image-type.enum';

export interface UserImageModelData extends ImageModelData {
    imageType: EImageType;
    userId: number;
}

export interface UserImageModel extends UserImageModelData {}

export class UserImageModel extends ImageModel {}
