import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class ChatApiService {
    constructor(private readonly http: HttpClient, private readonly appConfigService: AppMainConfigService) {}

    setActiveSlot(slotId: number): Observable<void> {
        const url = [this.appConfigService.getConfig().chatUrl, 'web-hooks', 'events', 'timeslot', slotId].join('/');
        return this.http.patch<void>(url, {}).pipe(publishReplay(1), refCount());
    }
}
