import { FighterApiResponse } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-api.response';
import { EFighterExperience } from '@fca-app/enums/fighter-experience.enum';
import { EFighterWeight } from '@fca-app/enums/fighter-weight.enum';
import { EUserGender } from '@fca-app/enums/gender.enum';
import { ESearchDistance } from '@fca-app/enums/search-distance.enum';
import { EUnitSystem } from '@fca-app/enums/unit-system.enum';
import { UserImageFactory } from '@fca-app/models/images/user-image/user-image.factory';
import { UserImageMapper } from '@fca-app/models/images/user-image/user-image.mapper';
import { LocationAddressFactory } from '@fca-app/models/location-address/location-address.factory';
import { LocationAddressMapper } from '@fca-app/models/location-address/location-address.mapper';
import { FighterModelData } from '@fca-app/models/users/fighter/fighter.model';
import { UserMapper } from '@fca-app/models/users/user/user.mapper';
import { EPenalty } from '@fca-app/shared/enums/penalty.enum';
import * as moment from 'moment';

export class FighterMapper {
    mapData(raw: FighterApiResponse): FighterModelData {
        const userImgFactory = new UserImageFactory();
        const userImgMapper = new UserImageMapper();
        const fullFaceAvatar = (raw.images || [])
            .filter(img => img.original && img.imageType === 'FULL_FACE_AVATAR')
            .pop();
        const halfLengthAvatar = (raw.images || [])
            .filter(img => img.original && img.imageType === 'HALF_LENGTH_AVATAR')
            .pop();

        return {
            ...new UserMapper().mapData(raw),
            firstName: raw.firstName,
            lastName: raw.lastName,
            nickname: raw.nickname || '',
            verified: raw.verified,
            profileFillingInfo: raw.profileFillingInfo,
            ...(raw.residence && {
                residence: new LocationAddressFactory().getModelFromData(
                    new LocationAddressMapper().mapData(raw.residence)
                ),
            }),
            images: (raw.images || []).map(rawImg => userImgFactory.getModelFromData(userImgMapper.mapData(rawImg))),
            ...(fullFaceAvatar && {
                fullFaceAvatar: userImgFactory.getModelFromData(userImgMapper.mapData(fullFaceAvatar)),
            }),
            ...(halfLengthAvatar && {
                halfLengthAvatar: userImgFactory.getModelFromData(userImgMapper.mapData(halfLengthAvatar)),
            }),
            details: {
                birthdate: new Date(raw.details.birthdate),
                club: raw.details.club,
                coaches: raw.details.coaches,
                draw: raw.details.draw,
                experience: raw.details.experience as EFighterExperience,
                gender: raw.details.gender as EUserGender,
                fightStyle: raw.details.fightStyle,
                handsSize: raw.details.handsSize,
                height: raw.details.height,
                weight: raw.details.weight as EFighterWeight,
                isActive: raw.details.isActive,
                loss: raw.details.loss,
                ranking: raw.details.ranking,
                searchDistance: raw.details.searchDistance as ESearchDistance,
                sigStrikes: raw.details.sigStrikes,
                stand: raw.details.stand,
                takedowns: raw.details.takedowns,
                unitSystem: raw.details.unitSystem as EUnitSystem,
                win: raw.details.win,
                winsByKo: raw.details.winsByKo,
                winsBySub: raw.details.winsBySub,
            },
            socials: raw.socials || [],
            phone: raw.phoneNumber,
            email: raw.email,
            createDate: raw.createDate,
            penalties: raw.penalties.map(penalty => ({
                id: penalty.id,
                reason: penalty.reason as EPenalty,
                date: moment(penalty.date),
                comment: penalty.comment,
            })),
            ...(raw.penaltyInfo && {
                penaltyInfo: {
                    banned: raw.penaltyInfo.banned,
                    unbanDate: raw.penaltyInfo.unbanDate,
                },
            }),
        };
    }
}
