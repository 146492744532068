<div style="padding-left: 10px; padding-right: 10px">
  <nz-row [nzGutter]="12">
    <nz-col
      [nzXs]="{ span: 24, offset: 0 }"
      [nzSm]="{ span: 24, offset: 0 }"
      [nzMd]="{ span: 24, offset: 0 }"
      [nzLg]="{ span: 24, offset: 0 }"
      [nzXl]="{ span: 24, offset: 0 }"
    >
      <nz-card
        [nzBordered]="false"
        nzTitle="Online"
        [nzBodyStyle]="{ padding: '0px' }"
      >
        <ngx-charts-line-chart
          #chartComponent
          [scheme]="colorScheme"
          [legend]="false"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxis]="true"
          [yAxis]="true"
          [xAxisLabel]="'Date'"
          [yAxisLabel]="'Users'"
          [timeline]="true"
          [results]="multi$ | async"
        >
        </ngx-charts-line-chart>
      </nz-card>
    </nz-col>
  </nz-row>
</div>
