import {
    AccountInfoTransactionItemHistoryItemModel,
    AccountInfoTransactionItemHistoryItemModelData,
} from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item-history-item/account-info-transaction-item-history-item.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class AccountInfoTransactionItemHistoryItemFactory extends ModelFactory<
    AccountInfoTransactionItemHistoryItemModelData,
    AccountInfoTransactionItemHistoryItemModel
> {
    protected getInstance(): AccountInfoTransactionItemHistoryItemModel {
        return new AccountInfoTransactionItemHistoryItemModel();
    }
}
