import { EVideoStatus } from "@fca-app/api/fca/arenas/interfaces/response/video-api.response";

export interface VideoModelData {
    id: number;
    name: string;
    status: EVideoStatus;
    streamUrl: string | null;
    videoUrl: string | null;
}

export interface VideoModel extends VideoModelData {}

export class VideoModel {}
