import { NgModule } from '@angular/core';
import { ChatApiService } from '@fca-app/api/chat/chat-api.service';
import { FcaApiModule } from '@fca-app/api/fca/fca-api.module';
import { GeocodingApiService } from '@fca-app/api/geocoding/geocoding-api.service';
import { VideoMetricsApiService } from '@fca-app/api/video-metrics/video-metrics-api.service';

@NgModule({
    declarations: [],
    imports: [FcaApiModule],
    providers: [GeocodingApiService, VideoMetricsApiService, ChatApiService],
})
export class ApiModule {}
