import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import Hls from 'hls.js';

export interface VideoData {
    timestampFirstFragment: number;
    currentTime: number;
}

@Component({
    selector: 'video-viewer',
    styleUrls: ['./video-viewer.component.scss'],
    templateUrl: './video-viewer.component.html',
})
export class VideoViewerComponent implements AfterViewInit {
    readonly hls = new Hls();
    readonly Hls = Hls;

    @Input() videoSrc: string | null;
    @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;

    private timestampFirstFragment: number | null = null;

    getVideoData(): VideoData {
        return {
            timestampFirstFragment: Number(this.timestampFirstFragment),
            currentTime: Number((this.hls as any).streamController.lastCurrentTime),
        };
    }

    ngAfterViewInit(): void {
        if (Hls.isSupported() && this.videoSrc) {
            this.hls.attachMedia(this.videoElementRef!.nativeElement);
            this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                this.hls.loadSource(this.videoSrc!);
                this.hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
                    this.timestampFirstFragment = data.frag.programDateTime;
                    this.hls.off(Hls.Events.FRAG_CHANGED);
                });
            });
        }
    }

    play(): void {
        this.videoElementRef?.nativeElement.play();
    }

    pause(): void {
        this.videoElementRef?.nativeElement.pause();
    }
}
