import {
    RealtimeVideoMetricsModel,
    RealtimeVideoMetricsModelData,
} from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class RealtimeVideoMetricsFactory extends ModelFactory<
    RealtimeVideoMetricsModelData,
    RealtimeVideoMetricsModel
> {
    public getEmptyModel(): RealtimeVideoMetricsModel {
        return this.getModelFromData({
            mostPopularCountry: '-',
            mostPopularPlatform: '-',
            mostPopularQuality: '-',
            users: 0,
        });
    }

    protected getInstance(): RealtimeVideoMetricsModel {
        return new RealtimeVideoMetricsModel();
    }
}
