<section class="cover min-vh-100" *ngIf="!(pintSent$ | async); else pinTemplate">
	<div class="container center-box">
		<form class="row g-3" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
			<div class="col-auto">
				<div class="input-group mb-2 mr-sm-2">
					<input
						type="email"
						class="form-control"
						formControlName="email"
						placeholder="admin@spartacus-mma.com"
					>
				</div>
			</div>
			<div class="col-auto sign-in-btns">
			  <button type="submit" [disabled]='!emailForm.valid' class="btn btn-danger mb-3 btn-ripple">Send PIN</button>
			  <button type="submit" (click)='signInGoogle()' nzSize='large' class="btn btn-danger mb-3 btn-ripple">
          Continue with google <i nz-icon nzType="google" nzTheme="outline"></i>
        </button>
			</div>
		</form>
	</div>
</section>

<ng-template #pinTemplate>
  <section class="min-vh-100 cover">
    <div class="container center-box">
      <form class="row g-3" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div class="col-auto text-white">
          <div class="input-group mb-2 mr-sm-2">
            <code-input
              [codeLength]="codeLength"
              (codeCompleted)="onCodeCompleted($event)"
            >
            </code-input>
          </div>
        </div>
      </form>
    </div>
  </section>
</ng-template>
