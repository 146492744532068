import { Injectable } from '@angular/core';
import { CheckUserParams } from '@fca-app/api/fca/email/interfaces/params/check-user.params';
import { SendSmsParams } from '@fca-app/api/fca/email/interfaces/params/send-sms.params';
import { CheckUserResponse } from '@fca-app/api/fca/email/interfaces/response/check-user.response';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaEmailApiService extends FcaApiServiceBase {
    public sendSMS(sendSMSDto: SendSmsParams): Observable<void> {
        const url = [this.apiUrl, 'email', 'admin', 'send-pincode'].join('/');
        return this.http
            .post<void>(url, sendSMSDto, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    public checkUser(checkUserDto: CheckUserParams): Observable<CheckUserResponse> {
        const url = [this.apiUrl, 'email', 'check-user'].join('/');
        return this.http
            .post<CheckUserResponse>(url, checkUserDto, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }
}
