import { GeoCoords } from '@fca-app/shared/interfaces/geo-coords.interface';

export interface GeocodingModelData {
    fullAddress: {
        name: string;
        address: {
            country: string;
            city: string;
            street: string;
            building: string;
        };
    };
    formattedAddress: string;
    location: GeoCoords;
}

export interface GeocodingModel extends GeocodingModelData {}

export class GeocodingModel {}
