import {
  FightEventWeightingModel,
  FightEventWeightingModelData
} from "@fca-app/models/users/arena/fight-event/fight-event-weighting/fight-event-weighting.model";
import { ModelFactory } from "@fca-app/shared/abstract/model-factory.abstract";

export class FightEventWeightingFactory extends ModelFactory<FightEventWeightingModelData, FightEventWeightingModel>{
  protected getInstance(): FightEventWeightingModel {
    return new FightEventWeightingModel();
  }
}
