import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponse } from '@fca-app/api/fca/auth/interfaces/response/login.response';
import { RefreshTokenResponse } from '@fca-app/api/fca/auth/interfaces/response/refresh-token.response';
import { LoginGoogleParams, LoginParams } from '@fca-app/api/fca/email/interfaces/params/login.params';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { AdminApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaAuthApiService extends FcaApiServiceBase {
    public login(loginDto: LoginParams): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'login'].join('/');

        return this.http
            .post<LoginResponse>(url, loginDto, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    public generateNewTokens(refreshToken: string): Observable<RefreshTokenResponse> {
        const url = [this.apiUrl, 'auth', 'refresh'].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                ...this.defaultHeaders,
                refresh: refreshToken,
            }),
        };

        return this.http.post<RefreshTokenResponse>(url, { deviceIdentifier: 'deviceIdentifier11' }, httpOptions);
    }

    googleSignIn(token: string, params: LoginGoogleParams): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'google', 'admin', 'login'].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                ...this.defaultHeaders,
                'x-google-token': token,
            }),
        };

        return this.http
            .post<LoginResponse>(url, { ...params, provider: 'google' }, httpOptions)
            .pipe(publishReplay(1), refCount());
    }

    public getAuthUser(): Observable<AdminApiResponse> {
        const url = [this.apiUrl, 'users', 'current'].join('/');

        return this.http
            .get<AdminApiResponse>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }
}
