import { FightEventImageApiResponse } from '@fca-app/api/fca/images/interfaces/response/image-api.response';
import { FightEventImageModelData } from '@fca-app/models/images/fight-event-image/fight-event-image.model';
import { ImageMapper } from '@fca-app/models/images/image.mapper';

export class FightEventImageMapper {
    mapData(raw: FightEventImageApiResponse): FightEventImageModelData {
        return {
            ...new ImageMapper().mapData(raw),
            fightEventId: raw.fightEventId,
        };
    }
}
