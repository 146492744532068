import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DownloadDataService } from '@fca-app/services/download-data.service';
import { countDaysUpToMonth } from '@fca-app/shared/helpers/calculate-days-up-to-month';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export interface IStatisticsFeedbacksRequest {
    activity: string | null;
}

@UntilDestroy()
@Component({
    selector: 'statistics-feedbacks',
    templateUrl: './statistics-feedbacks.component.html',
    styleUrls: ['./statistics-feedbacks.component.scss'],
})
export class StatisticsFeedbacksComponent implements OnInit, AfterViewInit {
    filterChange$ = new Subject<boolean>();

    readonly activities: { label: string; value: string | null }[] = [
        {
            label: 'All time',
            value: null,
        },
        {
            label: '1 day',
            value: '1',
        },
        {
            label: '7 days',
            value: '7',
        },
        {
            label: '14 days',
            value: '14',
        },
        {
            label: '21 days',
            value: '21',
        },
        ...Array.from({ length: 12 }, (_, i) => i + 1).map(num => ({
            label: `${num} month${num === 1 ? '' : 's'}`,
            value: String(countDaysUpToMonth(num)),
        })),
    ];

    request: IStatisticsFeedbacksRequest = {
        activity: '7',
    };

    constructor(
        public readonly preloadService: FcaPreloadService,
        private readonly downloadDataService: DownloadDataService,
        private readonly nzNotificationService: NzNotificationService
    ) {}

    onDownloadClick(): void {
        this.preloadService.preload(true);
        this.downloadDataService
            .downloadUsersFeedbacksCsv(this.request.activity !== null ? Number(this.request.activity) : undefined)
            .pipe(
                take(1),
                untilDestroy(this),
                finalize(() => this.preloadService.preload(false))
            )
            .subscribe(res => {
                if (!res) {
                    this.nzNotificationService
                        .info('Empty result', 'Data is empty', {
                            nzDuration: 5000,
                        })
                        .onClick.pipe(take(1), untilDestroy(this));
                }
            });
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.filterChange$.next(true);
    }
}
