/**
 * Сервис инкапсулирует знание о общей конфигурации приложения, которая необходима всему приложению
 */
import { Inject, Injectable } from '@angular/core';
import { AppMainConfig, APP_MAIN_CONFIG } from './app-main.config';

@Injectable()
export class AppMainConfigService {
    constructor(@Inject(APP_MAIN_CONFIG) private appMainConfig: AppMainConfig) {}

    public getConfig(): AppMainConfig {
        return this.appMainConfig;
    }
}
