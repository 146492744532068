import { LocationAddressModel } from '../location-address/location-address.model';

export interface LocationModelData {
    id: number;
    name: string;
    address: LocationAddressModel;
    tz: string;
    link: string | null;
    description: string | null;
    isPending: boolean;
}

export interface LocationModel extends LocationModelData {}

export class LocationModel {}
