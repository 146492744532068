export enum EWithdrawMoneyType {
    Donate = 'donate',
    Honorarium = 'honorarium',
}

export enum EWithdrawMoneyStatus {
    Pending = 'pending',
    Withdrawn = 'withdrawn',
    Canceled = 'canceled',
}

export interface TransactionHistoryItemResponse {
    amount: number;
    type: EWithdrawMoneyType;
    created: string;
}

export interface TransactionHistoryWithdrawItemResponse extends TransactionHistoryItemResponse {
    status: EWithdrawMoneyStatus;
}

export type GroupedTransactionsType = 'withdraw' | 'money';

export interface GroupedTransactionsDataResponse {
    amount: number;
    type: GroupedTransactionsType;
    history: (TransactionHistoryItemResponse | TransactionHistoryWithdrawItemResponse)[];
}

export interface FighterAccountInfoResponse {
    balance: number;
    transactions: GroupedTransactionsDataResponse[];
    withdrawAvailable: boolean;
}
