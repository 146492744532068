import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FcaStoreService } from '@fca-app/store/store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-manage-chat-iframe',
    templateUrl: './manage-chat-iframe.component.html',
    styleUrls: ['./manage-chat-iframe.component.scss'],
})
export class ManageChatIframeComponent implements OnInit {
    @Input() fightEvent: FightEventModel;

    constructor(
        private readonly storeService: FcaStoreService,
        private readonly appConfig: AppMainConfigService,
        private domSanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {}

    get urlSource(): Observable<SafeResourceUrl> {
        const prepareChatUrl = (jwt: string) =>
            `${this.appConfig.getConfig().chatUrl}/chat?jwt=${jwt}&fightEventId=${this.fightEvent.id}`;

        return this.storeService.getAuthData().pipe(
            map(authData => {
                return this.domSanitizer.bypassSecurityTrustResourceUrl(prepareChatUrl(authData!.jwt));
            })
        );
    }
}
