import { ArenaFightSlotApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/arena-fight-slot-api.response';
import { FightEventFactory } from '@fca-app/models/users/arena/fight-event/fight-event.factory';
import { FightEventMapper } from '@fca-app/models/users/arena/fight-event/fight-event.mapper';
import { FightTimeSlotModelData } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { FightFactory } from '@fca-app/models/users/arena/fight-event/fight/fight.factory';
import { FightMapper } from '@fca-app/models/users/arena/fight-event/fight/fight.mapper';
import * as moment from 'moment-timezone';

export class FightTimeSlotMapper {
    mapData(raw: ArenaFightSlotApiResponse, timezone: string): FightTimeSlotModelData {
        return {
            id: raw.id,
            fromDate: moment.utc(raw.fromDate),
            toDate: moment.utc(raw.toDate),
            fightEvent:
                raw.fightEvent &&
                new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw.fightEvent)),
            fight: raw.fight && new FightFactory().getModelFromData(new FightMapper().mapData(raw.fight, timezone)),
            locationId: raw.locationId,
            fightId: raw.fightId,
            isMainCard: raw.isMainCard,
            fightLink: raw.fightLink || null,
        };
    }
}
