import {
    FightTimeSlotModel,
    FightTimeSlotModelData,
} from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class FightTimeSlotFactory extends ModelFactory<FightTimeSlotModelData, FightTimeSlotModel> {
    protected getInstance(): FightTimeSlotModel {
        return new FightTimeSlotModel();
    }
}
