import { GroupedTransactionsDataResponse } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemHistoryItemFactory } from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item-history-item/account-info-transaction-item-history-item.factory';
import { AccountInfoTransactionItemHistoryItemMapper } from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item-history-item/account-info-transaction-item-history-item.mapper';
import { AccountInfoTransactionItemModelData } from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item.model';

export class AccountInfoTransactionItemMapper {
    mapData(raw: GroupedTransactionsDataResponse): AccountInfoTransactionItemModelData {
        return {
            amount: raw.amount,
            type: raw.type,
            history: (raw.history || []).map(rawHistoryItem =>
                new AccountInfoTransactionItemHistoryItemFactory().getModelFromData(
                    new AccountInfoTransactionItemHistoryItemMapper().mapData(rawHistoryItem)
                )
            ),
        };
    }
}
