import { formatNumber } from '@angular/common';
import { IHistoryVideoMetrics } from '@fca-app/api/video-metrics/video.metrics.interface';
import { HistoryVideoMetricsModelData } from '@fca-app/models/video-metrics/history/history-video-metrics.model';

export class HistoryVideoMetricsMapper {
    mapData(raw: IHistoryVideoMetrics, locale: string): HistoryVideoMetricsModelData {
        return {
            avgRequestTime: formatNumber(raw?.avgRequestTime, locale, '1.0-2'),
            country: raw.country ?? '-',
            maxDownload: formatNumber(raw.maxDownload, locale, '1.0-2'),
            maxOnline: raw.maxOnline.toString(),
            online: formatNumber(raw.online, locale, ''),
            popularCountry: raw.popularCountry ?? '-',
            popularQuality: raw.popularQuality ?? '-',
            quality: raw.quality ?? '-',
            requests: raw.requests.toString(),
            totalGb: formatNumber(raw.totalGb, locale, '1.0-2'),
            totalUsers: raw.totalUsers.toString(),
        };
    }
}
