import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { FanFactory } from '@fca-app/models/users/fan/fan.factory';
import { FanMapper } from '@fca-app/models/users/fan/fan.mapper';
import { FanModel } from '@fca-app/models/users/fan/fan.model';
import { CreateFanParamsBuilder } from '@fca-app/services/params/builders/users/create/create-fan-params.builder';
import { CreateFanFormDataParams } from '@fca-app/services/params/users/create/create-fan-form-data.params';
import { CreateUserAbstractStrategy } from '@fca-app/services/strategies/create-user/create-user-abstract.strategy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CreateFanStrategy extends CreateUserAbstractStrategy {
    constructor(protected usersService: FcaUsersApiService) {
        super(usersService);
    }

    create(data: CreateFanFormDataParams): Observable<FanModel> {
        const params = new CreateFanParamsBuilder().build(data);

        return this.usersService
            .createFan(params)
            .pipe(map(raw => new FanFactory().getModelFromData(new FanMapper().mapData(raw))));
    }
}
