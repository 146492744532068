import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardHomeModule } from '@fca-app/dashboard/components/home/home.module';
import { ManageModule } from '@fca-app/dashboard/components/manage/manage.module';
import { MatchmakingModule } from '@fca-app/dashboard/components/matchmaking/matchmaking.module';
import { DashboardStatisticsModule } from '@fca-app/dashboard/components/statistics/statistics.module';
import { DashboardUsersManagementModule } from '@fca-app/dashboard/components/users-management/users-management.module';
import { DownloadDataService } from '@fca-app/services/download-data.service';
import { ImagesService } from '@fca-app/services/images.service';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { WebHooksService } from '@fca-app/services/web-hooks.service';
import { PermissionsErrorComponent } from '@fca-app/shared/components/permissions-error/permissions-error.component';
import { SharedModule } from '@fca-app/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ArenasService } from '../services/arenas.service';
import { FightersService } from '../services/fighters.service';
import { GeocodingService } from '../services/geocoding.service';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
    declarations: [DashboardComponent, PermissionsErrorComponent],
    imports: [
        NgxPermissionsModule.forRoot(),
        CommonModule,
        DashboardRoutingModule,
        DashboardHomeModule,
        DashboardUsersManagementModule,
        DashboardStatisticsModule,
        ManageModule,
        MatchmakingModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
    ],
    exports: [DashboardComponent],
    providers: [
        ArenasService,
        FightersService,
        GeocodingService,
        DownloadDataService,
        ImagesService,
        WebHooksService,
        VideoMetricsService,
    ],
})
export class DashboardModule {}
