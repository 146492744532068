import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { RealtimeVideoMetricsFactory } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.factory';
import { RealtimeVideoMetricsModel } from '@fca-app/models/video-metrics/realtime/realtime-video-metrics.model';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, interval } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'realtime-statistics-video',
    templateUrl: './realtime-statistics-video.component.html',
    styleUrls: ['./realtime-statistics-video.component.scss'],
})
export class RealtimeStatisticsVideoComponent implements OnInit {
    public videoId: string | null = null;
    public realtime$ = new BehaviorSubject<RealtimeVideoMetricsModel>(
        new RealtimeVideoMetricsFactory().getEmptyModel()
    );
    @Input() eventBus: EventEmitter<{
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }>;
    private refresher = interval(2 * 1000)
        .pipe(
            untilDestroy(this),
            mergeMap(() => this.videoMetricsService.getRealtimeMetrics(this.videoId).pipe(take(1)))
        )
        .subscribe(data => this.realtime$.next(data));

    constructor(private readonly videoMetricsService: VideoMetricsService) {}

    ngOnInit(): void {
        this.eventBus
            .pipe(
                untilDestroy(this),
                tap(data => (this.videoId = data.videoId)),
                mergeMap(_ => this.videoMetricsService.getRealtimeMetrics(this.videoId).pipe(take(1)))
            )
            .subscribe(data => this.realtime$.next(data));
    }
}
