import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconDefinition } from '@ant-design/icons-angular';
import {
    AccountBookFill,
    AlertFill,
    AlertOutline,
    AndroidOutline,
    AppleOutline,
    ArrowLeftOutline,
    BuildOutline,
    DeleteOutline,
    DeleteTwoTone,
    DollarCircleOutline,
    DownCircleTwoTone,
    DownloadOutline,
    FormOutline,
    GlobalOutline,
    GoogleOutline,
    HomeOutline,
    InfoCircleOutline,
    LogoutOutline,
    MenuFoldOutline,
    MenuUnfoldOutline,
    PlayCircleOutline,
    PlusCircleOutline,
    PlusOutline,
    ReloadOutline,
    TeamOutline,
    UpCircleTwoTone,
    UserOutline,
    WechatOutline,
} from '@ant-design/icons-angular/icons';
import { AddressSelectionComponent } from '@fca-app/shared/components/geo-address-selection/address-selection.component';
import { AppImageCropperComponent } from '@fca-app/shared/components/image-cropper/image-cropper.component';
import { ManageChatIframeComponent } from '@fca-app/shared/components/manage-chat-iframe/manage-chat-iframe.component';
import { ModalGeoAddressSelectionComponent } from '@fca-app/shared/components/modal-geo-address-selection/modal-geo-address-selection.component';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzTransButtonModule } from 'ng-zorro-antd/core/trans-button';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { NZ_ICON_DEFAULT_TWOTONE_COLOR, NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const icons: IconDefinition[] = [
    AccountBookFill,
    AlertOutline,
    AlertFill,
    UserOutline,
    TeamOutline,
    MenuFoldOutline,
    MenuUnfoldOutline,
    ArrowLeftOutline,
    FormOutline,
    DeleteOutline,
    HomeOutline,
    GlobalOutline,
    DownloadOutline,
    PlusOutline,
    UpCircleTwoTone,
    DownCircleTwoTone,
    DeleteTwoTone,
    PlayCircleOutline,
    GoogleOutline,
    PlusCircleOutline,
    ReloadOutline,
    LogoutOutline,
    WechatOutline,
    AppleOutline,
    AndroidOutline,
    InfoCircleOutline,
    DollarCircleOutline,
    BuildOutline,
];

@NgModule({
    declarations: [
        PageNotFoundComponent,
        AppImageCropperComponent,
        AddressSelectionComponent,
        ModalGeoAddressSelectionComponent,
        ManageChatIframeComponent,
    ],
    imports: [
        NgxPermissionsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzAffixModule,
        NzAlertModule,
        NzAnchorModule,
        NzAutocompleteModule,
        NzAvatarModule,
        NzBackTopModule,
        NzBadgeModule,
        NzButtonModule,
        NzBreadCrumbModule,
        NzCalendarModule,
        NzCardModule,
        NzCarouselModule,
        NzCascaderModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzCommentModule,
        NzDatePickerModule,
        NzDescriptionsModule,
        NzDividerModule,
        NzDrawerModule,
        NzDropDownModule,
        NzEmptyModule,
        NzFormModule,
        NzGridModule,
        NzI18nModule,
        NzIconModule,
        NzImageModule,
        NzInputModule,
        NzInputNumberModule,
        NzLayoutModule,
        NzListModule,
        NzMentionModule,
        NzMenuModule,
        NzMessageModule,
        NzModalModule,
        NzNoAnimationModule,
        NzNotificationModule,
        NzPageHeaderModule,
        NzPaginationModule,
        NzPopconfirmModule,
        NzPopoverModule,
        NzProgressModule,
        NzRadioModule,
        NzRateModule,
        NzResultModule,
        NzSelectModule,
        NzSkeletonModule,
        NzSliderModule,
        NzSpinModule,
        NzStatisticModule,
        NzStepsModule,
        NzSwitchModule,
        NzTableModule,
        NzTabsModule,
        NzTagModule,
        NzTimePickerModule,
        NzTimelineModule,
        NzToolTipModule,
        NzTransButtonModule,
        NzTransferModule,
        NzTreeModule,
        NzTreeViewModule,
        NzTreeSelectModule,
        NzTypographyModule,
        NzUploadModule,
        NzWaveModule,
        NzResizableModule,
        NzPipesModule,
        NzSpaceModule,
        ImageCropperModule,
    ],
    providers: [
        FcaPreloadService,
        {
            provide: NZ_ICON_DEFAULT_TWOTONE_COLOR,
            useValue: '#00ff00',
        }, // If not provided, Ant Design's official blue would be used
        { provide: NZ_ICONS, useValue: icons },
    ],
    exports: [
        NgxPermissionsModule,
        PageNotFoundComponent,
        AppImageCropperComponent,
        ManageChatIframeComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzAffixModule,
        NzAlertModule,
        NzAnchorModule,
        NzAutocompleteModule,
        NzAvatarModule,
        NzBackTopModule,
        NzBadgeModule,
        NzButtonModule,
        NzBreadCrumbModule,
        NzCalendarModule,
        NzCardModule,
        NzCarouselModule,
        NzCascaderModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzCommentModule,
        NzDatePickerModule,
        NzDescriptionsModule,
        NzDividerModule,
        NzDrawerModule,
        NzDropDownModule,
        NzEmptyModule,
        NzFormModule,
        NzGridModule,
        NzI18nModule,
        NzIconModule,
        NzImageModule,
        NzInputModule,
        NzInputNumberModule,
        NzLayoutModule,
        NzListModule,
        NzMentionModule,
        NzMenuModule,
        NzMessageModule,
        NzModalModule,
        NzNoAnimationModule,
        NzNotificationModule,
        NzPageHeaderModule,
        NzPaginationModule,
        NzPopconfirmModule,
        NzPopoverModule,
        NzProgressModule,
        NzRadioModule,
        NzRateModule,
        NzResultModule,
        NzSelectModule,
        NzSkeletonModule,
        NzSliderModule,
        NzSpinModule,
        NzStatisticModule,
        NzStepsModule,
        NzSwitchModule,
        NzTableModule,
        NzTabsModule,
        NzTagModule,
        NzTimePickerModule,
        NzTimelineModule,
        NzToolTipModule,
        NzTransButtonModule,
        NzTransferModule,
        NzTreeModule,
        NzTreeViewModule,
        NzTreeSelectModule,
        NzTypographyModule,
        NzUploadModule,
        NzWaveModule,
        NzResizableModule,
        NzPipesModule,
        NzSpaceModule,
        ImageCropperModule,
        AddressSelectionComponent,
        ModalGeoAddressSelectionComponent,
    ],
})
export class SharedModule {}
