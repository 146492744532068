import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { CodeInputModule } from 'angular-code-input';
import { GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AuthComponent } from './components/auth/auth.component';
import { PhoneFormatterPipe } from './pipes/phone-formatter.pipe';
import { AuthService } from './services/auth.service';

@NgModule({
    declarations: [AuthComponent, PhoneFormatterPipe],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, CodeInputModule, HttpClientModule, SocialLoginModule],
    providers: [
        AppMainConfigService,
        AuthService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1000866745065-csoe6lbgr3065o4nfhihhmhiaen94n7i.apps.googleusercontent.com'
                        ),
                    },
                ],
            },
        },
    ],

    exports: [AuthComponent],
})
export class AuthModule {}
