import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateArenaParams } from '@fca-app/api/fca/arenas/interfaces/params/update-arena.params';
import { UpdateFightEventTimeSlotsParams } from '@fca-app/api/fca/arenas/interfaces/params/update-fight-event-time-slots.params';
import { UpdateFightEventParams } from '@fca-app/api/fca/arenas/interfaces/params/update-fight-event.params';
import { UploadFightEventPhotoParams } from '@fca-app/api/fca/arenas/interfaces/params/upload-fight-event-photo.params';
import { ArenaEventApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response';
import { GetArenasApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/get-arenas-api.response';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { FightEventImageApiResponse } from '@fca-app/api/fca/images/interfaces/response/image-api.response';
import { SearchUsersParams } from '@fca-app/api/fca/users/interfaces/params/search-users.params';
import { LocationProviderApiResponse } from '@fca-app/api/fca/users/interfaces/response/location-provider-api.response';
import { PaginationQueryParams } from '@fca-app/api/shared/params/pagination-query.params';
import { EventStatus } from '@fca-app/enums/event-status.enum';
import { ELocationAction } from '@fca-app/enums/location-action.enum';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaArenasApiService extends FcaApiServiceBase {
    getArenas(queryParams: PaginationQueryParams): Observable<GetArenasApiResponse> {
        const url = [this.apiUrl, 'admin', 'users'].join('/');
        const httpParams = new HttpParams()
            .set('limit', queryParams.limit)
            .set('page', queryParams.page - 1)
            .set('roleName', EUserRoleName.LOCATION_PROVIDER);

        return this.http
            .get<GetArenasApiResponse>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    searchArenas(queryParams: SearchUsersParams): Observable<GetArenasApiResponse> {
        const url = [this.apiUrl, 'admin', 'users'].join('/');
        let httpParams: HttpParams = new HttpParams()
            .set('limit', queryParams.limit)
            .set('page', queryParams.page - 1)
            .set('roleName', EUserRoleName.LOCATION_PROVIDER);

        if (queryParams.searchTerm) {
            httpParams = httpParams.set('searchTerm', queryParams.searchTerm);
        }

        return this.http
            .get<GetArenasApiResponse>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    approveArena(locationId: number): Observable<void> {
        const url = [this.apiUrl, 'users', 'admin', 'locations', locationId].join('/');
        const httpParams: HttpParams = new HttpParams().set('action', ELocationAction.APPROVE);

        return this.http
            .patch<void>(url, {}, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    getArenaById(userId: number): Observable<LocationProviderApiResponse> {
        const url = [this.apiUrl, 'users', 'profile', userId].join('/');
        const httpOptions = { headers: this.defaultHeaders };

        return this.http.get<LocationProviderApiResponse>(url, httpOptions).pipe(publishReplay(1), refCount());
    }

    getArenaEvents(userId: number, statuses: EventStatus[]): Observable<ArenaEventApiResponse[]> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'arena', 'fight-events'].join('/');
        let httpParams: HttpParams = new HttpParams()
            .set('userId', userId)
            .set('limit', 50)
            .set('page', 0);
        statuses.forEach((status, idx) => (httpParams = httpParams.set(`status[${idx}]`, status)));

        return this.http
            .get<ArenaEventApiResponse[]>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    updateArenaInfo(arenaId: number, params: UpdateArenaParams): Observable<LocationProviderApiResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'location-provider', arenaId].join('/');

        return this.http
            .patch<LocationProviderApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    updateTimeSlotsForFightEvent(params: UpdateFightEventTimeSlotsParams): Observable<ArenaEventApiResponse> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'slots'].join('/');

        return this.http
            .post<ArenaEventApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    uploadFightEventPhoto(params: UploadFightEventPhotoParams): Observable<FightEventImageApiResponse> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'upload-fight-event-image'].join('/');
        const formData: FormData = new FormData();
        formData.append('position', String(params.position));
        formData.append('fightEventId', String(params.fightEventId));
        formData.append('image', params.file, params.file.name);

        return this.http.post<FightEventImageApiResponse>(url, formData).pipe(publishReplay(1), refCount());
    }

    updateFightEvent(params: UpdateFightEventParams): Observable<void> {
        const url = [this.apiUrl, 'fight-events', 'admin'].join('/');

        return this.http
            .patch<void>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }
}
