import { Injectable } from '@angular/core';
import { FcaFightsApiService } from '@fca-app/api/fca/fights/fca-fights-api.service';
import { FightFactory } from '@fca-app/models/users/arena/fight-event/fight/fight.factory';
import { FightMapper } from '@fca-app/models/users/arena/fight-event/fight/fight.mapper';
import { FightModel } from '@fca-app/models/users/arena/fight-event/fight/fight.model';
import { EFightResultType } from '@fca-app/shared/enums/fight-result-type.enum';
import { EFightWinner } from '@fca-app/shared/enums/fight-winner.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FightsService {
    constructor(private readonly fightsService: FcaFightsApiService) {}

    updateFightWinner(
        fightId: number,
        winner: EFightWinner,
        initiatorId: number,
        targetId: number,
        result: EFightResultType
    ): Observable<void> {
        return this.fightsService.updateFightWinner({ fightId, winner, initiatorId, targetId, result });
    }

    createFight(
        slotId: number,
        initiatorId: number,
        targetId: number,
        fightEventId: string,
        timezone: string = 'UTC'
    ): Observable<FightModel> {
        return this.fightsService
            .createFight({ slotId, initiatorId, targetId, fightEventId })
            .pipe(map(raw => new FightFactory().getModelFromData(new FightMapper().mapData(raw, timezone ?? 'UTC'))));
    }

    deleteFight(fightId: number, slotId: number): Observable<void> {
        return this.fightsService.deleteFight({ fightId, slotId });
    }

    updateFight(id: number, timezone: string = 'UTC', fightLink?: string | null): Observable<FightModel> {
        return this.fightsService
            .updateFight({ id, fightLink })
            .pipe(map(raw => new FightFactory().getModelFromData(new FightMapper().mapData(raw, timezone))));
    }
}
