import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';

export interface UserModelData {
    id: number;
    phoneNumber?: string;
    email: string;
    role: EUserRoleName;
}

export interface UserModel extends UserModelData {}

export class UserModel {}
