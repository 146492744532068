export enum EFighterWeight {
    STRAWWEIGHT = 'STRAWWEIGHT',
    BANTAMWEIGHT = 'BANTAMWEIGHT',
    FLYWEIGHT = 'FLYWEIGHT',
    FEATHERWEIGHT = 'FEATHERWEIGHT',
    LIGHTWEIGHT = 'LIGHTWEIGHT',
    WELTERWEIGHT = 'WELTERWEIGHT',
    MIDDLEWEIGHT = 'MIDDLEWEIGHT',
    LIGHTHEAVYWEIGHT = 'LIGHTHEAVYWEIGHT',
    HEAVYWEIGHT = 'HEAVYWEIGHT',
}
