export interface ImageModelData {
    id: number;
    positionId: number;
    createDate: number;
    updateDate: number;
    size: number;
    href: string;
    name: string;
    original: boolean;
}

export interface ImageModel extends ImageModelData {}

export class ImageModel {}
