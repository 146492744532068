import { Action, ActionReducer } from '@ngrx/store';
import { merge, pick } from 'lodash';

export function storageMetaReducer(config: { storageKey: string; saveKeys: string[]; storageService: Storage }[]) {
    let onInit = true;
    return <S, A extends Action = Action>(reducer: ActionReducer<S, A>) => {
        return (state: S, action: A): S => {
            let nextState = reducer(state, action);

            if (onInit) {
                // первая инициализация, восстанавливаем данные
                onInit = false;

                config.forEach(storageConfig => {
                    const savedState = JSON.parse(
                        storageConfig.storageService.getItem(storageConfig.storageKey) || '{}'
                    );
                    nextState = merge({}, nextState, savedState);
                });
            }

            config.forEach(storageConfig => {
                const stateToSave = pick(nextState, storageConfig.saveKeys);
                storageConfig.storageService.setItem(storageConfig.storageKey, JSON.stringify(stateToSave));
            });

            return nextState;
        };
    };
}
