import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IGraphMetrics,
    IHistoryVideoMetrics,
    IMapVideoMetrics,
    IRealtimeVideoMetrics,
} from '@fca-app/api/video-metrics/video.metrics.interface';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class VideoMetricsApiService {
    private readonly url = 'https://video.spartacus-mma.com/api/v1/video/metrics/';

    constructor(private readonly http: HttpClient) {}

    public history(from: number, to: number, videoId: string | null): Observable<IHistoryVideoMetrics> {
        return this.http
            .post<IHistoryVideoMetrics>(this.url + 'history', { from, to, videoId })
            .pipe(publishReplay(1), refCount());
    }

    public realtime(videoId: string | null): Observable<IRealtimeVideoMetrics> {
        return this.http
            .post<IRealtimeVideoMetrics>(this.url + 'realtime', { videoId })
            .pipe(publishReplay(1), refCount());
    }

    public map(from: number, to: number, videoId: string | null): Observable<IMapVideoMetrics> {
        return this.http
            .post<IMapVideoMetrics>(this.url + 'map', { from, to, videoId })
            .pipe(publishReplay(1), refCount());
    }

    public graph(
        from: number,
        to: number,
        step: number,
        videoId: string | null
    ): Observable<{ graph: IGraphMetrics[] }> {
        return this.http
            .post<{ graph: IGraphMetrics[] }>(this.url + 'graph', { from, to, step, videoId })
            .pipe(publishReplay(1), refCount());
    }
}
