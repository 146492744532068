import {
  ArenaEventWeightingInfo
} from "@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response";
import { FightEventStartInfoMapper } from "@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.mapper";
import { FightEventWeightingModelData } from "@fca-app/models/users/arena/fight-event/fight-event-weighting/fight-event-weighting.model";

export class FightEventWeightingMapper {
  mapData(raw: ArenaEventWeightingInfo): FightEventWeightingModelData {
    return {
      ...new FightEventStartInfoMapper().mapData(raw),
    };
  }
}
