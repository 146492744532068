import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { ImagesService } from '@fca-app/services/images.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { fileToBase64 } from '@fca-app/shared/helpers/file-to-base64.helper';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-arena-photo',
    styleUrls: ['./arena-photo.component.scss'],
    templateUrl: './arena-photo.component.html',
})
export class ArenaPhotoComponent implements OnInit {
    @Input() imageId?: number;
    @Input() imageType: EImageType;
    @Input() position = 0;
    @Input() userId: number;
    @Input() previewPlaceholder: string;
    @Output() onDelete = new EventEmitter<UserImageModel>();
    @Output() onChange = new EventEmitter<UserImageModel>();

    isModalVisible$ = new BehaviorSubject<boolean>(false);
    imageChangedEvent: Event;
    permissions = EAdminPermissions;

    constructor(
        private readonly nzImageService: NzImageService,
        private readonly nzNotificationService: NzNotificationService,
        private readonly imagesService: ImagesService,
        private readonly appConfigService: AppMainConfigService
    ) {}

    ngOnInit(): void {
        this.previewPlaceholder = this.previewPlaceholder || this.appConfigService.getConfig().previewImage;
    }

    onCloseCropper(): void {
        this.isModalVisible$.next(false);
    }

    click() {
        this.nzImageService.preview([{ src: this.previewPlaceholder, alt: 'Preview' }], { nzZoom: 0.7, nzRotate: 0 });
    }

    onDeletePhotoClick(): void {
        if (this.imageId) {
            this.imagesService
                .deleteImagesByIds(this.userId, [this.imageId])
                .pipe(take(1), untilDestroy(this))
                .subscribe(() => {
                    this.imageId = undefined;
                    this.previewPlaceholder = this.appConfigService.getConfig().previewImage;
                });
        }
    }

    edit(e: Event) {
        this.imageChangedEvent = e;
        this.isModalVisible$.next(true);
    }

    async onPhotoUpload(uploadedImage: UploadedCropperImage) {
        this.isModalVisible$.next(false);
        this.previewPlaceholder = String(await fileToBase64(uploadedImage.file));
        this.imagesService
            .uploadImage(this.userId, {
                file: uploadedImage.file,
                type: uploadedImage.type,
                position: this.position,
            })
            .pipe(
                take(1),
                switchMap(() => this.nzNotificationService.success('Success', '', { nzDuration: 1500 }).onClick),
                catchError(err => this.nzNotificationService.error(err.message, '', { nzDuration: 1500 }).onClick),
                untilDestroy(this)
            )
            .subscribe();
    }

    photoTypeString(imgType: EImageType): string {
        let type = '';

        switch (imgType) {
            case EImageType.ARENA_AVATAR:
                type = 'Arena avatar';
                break;
            case EImageType.ARENA_PHOTO:
                type = 'Arena photo';
                break;
            case EImageType.ARENA_PHOTO_BIG:
                type = 'Arena photo big';
                break;
            case EImageType.FULL_FACE_AVATAR:
                type = 'Fighter full face avatar';
                break;
            case EImageType.HALF_LENGTH_AVATAR:
                type = 'Fighter half length avatar';
                break;
        }
        return type;
    }
}
