import { Injectable } from "@angular/core";
import { FcaFightEventsApiService } from "@fca-app/api/fca/fight-events/fca-fight-events-api.service";
import { FightEventFactory } from "@fca-app/models/users/arena/fight-event/fight-event.factory";
import { FightEventMapper } from "@fca-app/models/users/arena/fight-event/fight-event.mapper";
import { FightEventModel } from "@fca-app/models/users/arena/fight-event/fight-event.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class FightEventsService {
  constructor(private readonly fightEventsService: FcaFightEventsApiService) {}

  getById(id: string): Observable<FightEventModel> {
    return this.fightEventsService.getFightEvent(id).pipe(
      map((raw) =>
        new FightEventFactory().getModelFromData(
          new FightEventMapper().mapData(raw)
        )
      )
    );
  }
}
