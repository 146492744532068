import { AfterViewInit, Component } from '@angular/core';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-arena-modal-carousel',
    templateUrl: './arena-gallery.component.html',
    styleUrls: ['./arena-gallery.component.scss'],
})
export class ArenaGalleryComponent implements AfterViewInit {
    isVisible = false;
    imgSources: UserImageModel[];
    title: string;

    constructor(private readonly arenaService: ArenasService) {}

    ngAfterViewInit() {
        this.arenaService.selectedArena$.pipe(untilDestroy(this)).subscribe(arena => {
            this.title = arena.location.name;
            this.imgSources = [...arena.images.filter(img => img.original)];
            this.isVisible = true;
        });
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }
}
