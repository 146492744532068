import { ImageApiResponse } from '@fca-app/api/fca/images/interfaces/response/image-api.response';
import { ImageModelData } from '@fca-app/models/images/image.model';

export class ImageMapper {
    mapData(raw: ImageApiResponse): ImageModelData {
        return {
            id: raw.id,
            createDate: raw.createDate,
            updateDate: raw.updateDate,
            size: raw.size,
            href: raw.href,
            original: raw.original,
            name: raw.name,
            positionId: raw.positionId,
        };
    }
}
