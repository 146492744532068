import { Component, Input } from '@angular/core';
import { EWithdrawMoneyStatus } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemModel } from '@fca-app/models/users/fighter/account-info/account-info-transaction-item/account-info-transaction-item.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { FightersService } from '@fca-app/services/fighters.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'fighter-details-account-info',
    styleUrls: ['./fighter-details-account-info.component.scss'],
    templateUrl: './fighter-details-account-info.component.html',
})
export class FighterDetailsAccountInfoComponent {
    @Input() fighter: FighterModel;

    permissions = EAdminPermissions;

    constructor(private readonly fightersService: FightersService, public readonly preloadService: FcaPreloadService) {}

    get balance(): string {
        return `$${this.prepareMoney(this.fighter.account!.balance)}`;
    }

    booleanToString(value: boolean): string {
        return value ? 'Yes' : 'No';
    }

    getItemText(transaction: AccountInfoTransactionItemModel): string {
        const receivedMoneyType = transaction.history[0].type;

        return transaction.type === 'withdraw'
            ? 'Withdraw'
            : `Received ${receivedMoneyType === 'donate' ? 'donate' : 'honorarium'}`;
    }

    getTransactionDate(transaction: AccountInfoTransactionItemModel): string {
        return moment(transaction.history[0].created)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    }

    transactionIsPending(transaction: AccountInfoTransactionItemModel): boolean {
        return transaction.type === 'withdraw' && transaction.history[0].status === EWithdrawMoneyStatus.Pending;
    }

    transactionIsWithdrawn(transaction: AccountInfoTransactionItemModel): boolean {
        return transaction.type === 'withdraw' && transaction.history[0].status === EWithdrawMoneyStatus.Withdrawn;
    }

    prepareMoney(value: number): number {
        return value / 100;
    }

    onConfirmPaymentClick(): void {
        this.preloadService.preload(true);
        this.fightersService
            .confirmWithdrawal(this.fighter.id)
            .pipe(take(1), untilDestroy(this))
            .subscribe(resp => {
                this.preloadService.preload(false);
                this.fighter.account = resp;
            });
    }
}
