import produce from 'immer';
import { set } from 'lodash';
import { Actions, ActionTypes } from './actions';
import { FcaState, FCA_INITIAL_STATE } from './state';

export function fcaFeatureReducer(state: FcaState = FCA_INITIAL_STATE, actions: Actions) {
    return produce<(draftState: FcaState, action: Actions) => FcaState>((draftState, action) => {
        switch (action.type) {
            case ActionTypes.SAVE_AUTH_DATA:
                return set(draftState, ['authData'], action.payload.data);
            case ActionTypes.CLEAR_AUTH_DATA:
                return set(draftState, ['authData'], null);
            case ActionTypes.SAVE_VIEW_ARENAS_PAGE:
                return set(draftState, ['viewArenasPage'], action.payload.page);
            case ActionTypes.SAVE_VIEW_FIGHTERS_PAGE:
                return set(draftState, ['viewFightersPage'], action.payload.page);
        }
    })(state, actions);
}
