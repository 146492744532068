import { Injectable } from '@angular/core';

export interface ICsvMatchmaking {
    id: string;
    status: string;
    event: string;
    name: string;
    surname: string;
    nickname: string;
    email: string;
    mobile: string;
    location: string;
    camp: string;
    coach: string;
    age: string;
    gender: string;
    weighting: string;
}

@Injectable()
export class MatchmakingDownloadService {
    public downloadFile(data: any[], filename = 'data'): void {
        const keys: Set<string> = new Set<string>();
        data.map(_ =>
            Object.keys(_).forEach(__ => {
                keys.add(__);
            })
        );
        const csvData = this.convertToCSV(data, Array.from(keys));
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const downalodLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser =
            navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            downalodLink.setAttribute('target', '_blank');
        }
        downalodLink.setAttribute('href', url);
        downalodLink.setAttribute('download', filename + '.csv');
        downalodLink.style.visibility = 'hidden';
        document.body.appendChild(downalodLink);
        downalodLink.click();
        document.body.removeChild(downalodLink);
    }

    public convertToCSV(objArray: any[], headerList: string[]): string {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '№,';
        for (const [index, _] of headerList.entries()) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = i + 1 + '';
            for (const [index, _] of headerList.entries()) {
                const head = headerList[index];
                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
    }
}
