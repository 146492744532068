import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { StatisticsFeedbacksComponent } from '@fca-app/dashboard/components/statistics/statistics-feedbacks/statistics-feedbacks.component';
import { StatisticsUsersComponent } from '@fca-app/dashboard/components/statistics/statistics-users/statistics-users.component';
import { BasicStatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/basic-statistics-video/basic-statistics-video.component';
import { MapsStatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/maps-statistics-video/maps-statistics-video.component';
import { OnlineGraphStatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/online-graph-statistics-video/online-graph-statistics-video.component';
import { RealtimeStatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/realtime-statistics-video/realtime-statistics-video.component';
import { StatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video.component';
import { TitleStatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/title-statistics-video/title-statistics-video.component';
import { DashboardStatisticsComponent } from '@fca-app/dashboard/components/statistics/statistics.component';
import { SharedModule } from '@fca-app/shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        DashboardStatisticsComponent,
        StatisticsUsersComponent,
        StatisticsVideoComponent,
        StatisticsFeedbacksComponent,
        BasicStatisticsVideoComponent,
        TitleStatisticsVideoComponent,
        OnlineGraphStatisticsVideoComponent,
        MapsStatisticsVideoComponent,
        RealtimeStatisticsVideoComponent,
    ],
    imports: [
        FormsModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        NgxChartsModule,
    ],
    providers: [],
    exports: [DashboardStatisticsComponent],
})
export class DashboardStatisticsModule {}
