import { FightTimeSlotModel } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { EFightStatus } from '@fca-app/shared/enums/fight-status.enum';
import { EFightWinner } from '@fca-app/shared/enums/fight-winner.enum';

export interface FightModelData {
    id: number;
    status: EFightStatus;
    winner: EFightWinner | null;
    initiator: FighterModel;
    target: FighterModel;
    slot: FightTimeSlotModel;
    fightLink: string | null;
}

export interface FightModel extends FightModelData {}

export class FightModel {}
