<div class="slot-card" (click)='onClickByCard()'>
  <nz-card nzHoverable style="width:240px" [nzCover]="initiatorCardTemplate">
    <nz-card-meta nzTitle="{{ fighter?.firstName || '' }} {{ fighter?.lastName || '' }}"
                  [nzDescription]="targetDescription">
    </nz-card-meta>
    <div *ngIf='!isFighterExists'>
      <div style='text-align: center'><b>Choose a fighter</b></div>
      <div class="example-input">
        <input
          #fightersSearchInput
          nz-input
          [placeholder]='slotType'
          (input)="onFighterSearchChange(fightersSearchInput.value)"
          [nzAutocomplete]="auto"
        />
        <nz-autocomplete #auto (selectionChange)="onSelectFighter($event)">
          <nz-auto-option *ngFor="let fighter of foundFighters" [nzValue]="fighter" nzLabel="{{ fighter.fullName }}">
            {{ fighter.fullName }}
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>
  </nz-card>
  <ng-template #initiatorCardTemplate>
    <img loading="lazy" alt="example" [src]="fighter?.fullFaceAvatar?.href || placeholder" />
  </ng-template>
  <ng-template #targetDescription>
    <div *ngIf="isFighterExists" class='description'>
      <div>Weight: {{ fighter?.details?.weight || 0 }}</div>
      <div class="rating">
        <span>Rating:</span>
        <nz-input-number [ngModel]="rating"
                         [nzMin]="1"
                         [nzStep]="1"
                         [nzDisabled]="!slot.hasFight()"
                         (ngModelChange)="onRatingChange($event)"
        ></nz-input-number>
      </div>
    </div>
  </ng-template>
  <div class="winner" *ngIf="isWinner" >
    <img loading="lazy" nz-tooltip nzTooltipTitle="Winner" src="assets/images/winner.png" />
  </div>
  <div class="draw" *ngIf="isDraw">
    <img loading="lazy" nz-tooltip nzTooltipTitle="Draw" src="assets/images/draw.png" />
  </div>
</div>
