import { AuthData } from '@fca-app/auth/interfaces/tokens-data.interface';

export interface FcaState {
    authData: AuthData | null;
    viewArenasPage: number;
    viewFightersPage: number;
}

export const FCA_INITIAL_STATE: FcaState = {
    authData: null,
    viewArenasPage: 1,
    viewFightersPage: 1,
};
