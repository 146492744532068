import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@UntilDestroy()
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    readonly codeLength: number = 6;

    emailForm: FormGroup;
    pintSent$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly authService: AuthService,
        private readonly nzNotificationService: NzNotificationService,
        private router: Router
    ) {
        this.emailForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    ngOnInit(): void {}

    signInGoogle() {
        this.authService
            .googleLogin()
            .pipe(
                take(1),
                tap(() => {
                    this.router.navigate(['dashboard']);
                }),
                catchError(err => {
                    if (err.error !== 'popup_closed_by_user') {
                        this.showLoginErrorNotification();
                    }
                    return of(null);
                }),
                untilDestroy(this)
            )
            .subscribe();
    }

    public onCodeCompleted(pincode: string) {
        this.authService
            .checkUser({ pincode, email: this.email.value })
            .pipe(
                switchMap(registrationToken => this.authService.login(registrationToken, this.email.value)),
                tap(() => this.router.navigate(['dashboard'])),
                catchError(() => {
                    this.email.setValue('');
                    this.pintSent$.next(false);
                    return of(null);
                }),
                untilDestroy(this)
            )
            .subscribe();
    }

    public onSubmit() {
        if (this.emailForm.valid) {
            this.authService
                .sendSMS(this.email.value)
                .pipe(
                    tap(() => this.pintSent$.next(true)),
                    catchError(err => {
                        this.showLoginErrorNotification();
                        return of(null);
                    }),
                    untilDestroy(this)
                )
                .subscribe();
        }
    }

    public get email(): AbstractControl {
        return this.emailForm.controls.email;
    }

    private showLoginErrorNotification(): void {
        this.nzNotificationService
            .error('Something went wrong', 'Email not found or your email is not corporate or user is not admin', {
                nzDuration: 5000,
            })
            .onClick.pipe(take(1), untilDestroy(this));
    }
}
