import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { FighterFactory } from '@fca-app/models/users/fighter/fighter.factory';
import { FighterMapper } from '@fca-app/models/users/fighter/fighter.mapper';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { CreateFighterParamsBuilder } from '@fca-app/services/params/builders/users/create/create-fighter-params.builder';
import { CreateFighterFormDataParams } from '@fca-app/services/params/users/create/create-fighter-form-data.params';
import { CreateUserAbstractStrategy } from '@fca-app/services/strategies/create-user/create-user-abstract.strategy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CreateFighterStrategy extends CreateUserAbstractStrategy {
    constructor(protected usersService: FcaUsersApiService) {
        super(usersService);
    }

    create(data: CreateFighterFormDataParams): Observable<FighterModel> {
        const params = new CreateFighterParamsBuilder().build(data);

        return this.usersService
            .createFighter(params)
            .pipe(map(raw => new FighterFactory().getModelFromData(new FighterMapper().mapData(raw))));
    }
}
