import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ManageAdminsItemComponent } from '@fca-app/dashboard/components/manage/manage-admins/manage-admins-item/manage-admins-item.component';
import { ManageAdminsComponent } from '@fca-app/dashboard/components/manage/manage-admins/manage-admins.component';
import { ManageComponent } from '@fca-app/dashboard/components/manage/manage.component';
import { AdminsService } from '@fca-app/services/admins.service';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [ManageComponent, ManageAdminsComponent, ManageAdminsItemComponent],
    imports: [FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [AdminsService],
    exports: [ManageComponent],
})
export class ManageModule {}
