<div style="padding-left: 10px; padding-right: 10px;">
  <div style="display: flex; padding-top: 10px;">
    <h4>History analytics</h4>
  </div>
  <nz-row [nzGutter]="12">
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.requests"
          [nzTitle]="'Total requests'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.totalUsers"
          [nzTitle]="'Unique users'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.popularQuality"
          [nzTitle]="'Popular quality'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.popularCountry"
          [nzTitle]="'Popular country'"
        ></nz-statistic>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.totalGb"
          [nzTitle]="'Total downloaded'"
          [nzSuffix]="totalDownloadedTmp"
        ></nz-statistic>
        <ng-template #totalDownloadedTmp>
          <label class="suffix">Gb</label>
        </ng-template>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.avgRequestTime"
          [nzTitle]="'Avg request time'"
          [nzSuffix]="avgRequestTimeTmp"
        ></nz-statistic>
        <ng-template #avgRequestTimeTmp>
          <label class="suffix">ms</label>
        </ng-template>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.maxOnline"
          [nzTitle]="'Max online'"
          [nzSuffix]="maxOnlineTpl"
        ></nz-statistic>
        <ng-template #maxOnlineTpl>
          <label class="suffix">users</label>
        </ng-template>
      </nz-card>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12, offset: 0 }"
      [nzSm]="{ span: 12, offset: 0 }"
      [nzMd]="{ span: 8, offset: 0 }"
      [nzLg]="{ span: 6, offset: 0 }"
      [nzXl]="{ span: 4, offset: 0 }"
    >
      <nz-card>
        <nz-statistic
          [nzValue]="(historyMetrics$ | async)?.maxDownload"
          [nzTitle]="'Max download'"
          [nzSuffix]="downloadTpl"
        ></nz-statistic>
        <ng-template #downloadTpl>
          <label class="suffix">Gb/min</label>
        </ng-template>
      </nz-card>
    </nz-col>
  </nz-row>
</div>
