import { CreateFanParams } from '@fca-app/api/fca/users/interfaces/params/create/create-fan.params';
import { CreateUserBaseBuilder } from '@fca-app/services/params/builders/users/create/create-user-base.builder';
import { CreateFanFormDataParams } from '@fca-app/services/params/users/create/create-fan-form-data.params';

export class CreateFanParamsBuilder extends CreateUserBaseBuilder {
    build(data: CreateFanFormDataParams): CreateFanParams {
        return {
            ...super.build(data),
            ...(data.fullname && {
                fullname: data.fullname,
            }),
            ...(data.nickname && {
                nickname: data.nickname,
            }),
            firstName: data.firstName,
            lastName: data.lastName,
        };
    }
}
