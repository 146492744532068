import { ArenaEventStartInfo } from '@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response';
import { FightEventStartInfoModelData } from '@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.model';
import { VideoFactory } from '@fca-app/models/video/video.factory';
import { VideoMapper } from '@fca-app/models/video/video.mapper';
import * as moment from 'moment-timezone';

export class FightEventStartInfoMapper {
    mapData(raw: ArenaEventStartInfo): FightEventStartInfoModelData {
        return {
            status: raw.status,
            fromDate: moment.utc(raw.fromDate),
            toDate: moment.utc(raw.toDate),
            ...(raw.video && {
                video: new VideoFactory().getModelFromData(new VideoMapper().mapData(raw.video)),
            }),
        };
    }
}
