import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationModel } from '@fca-app/models/location/location.model';

@Component({
    selector: 'app-arena-info',
    styleUrls: ['./arena-info.component.scss'],
    templateUrl: './arena-info.component.html',
})
export class ArenaInfoComponent {
    @Input() data: LocationModel;
    @Output() change = new EventEmitter<void>();
    @Output() openAddressSelection = new EventEmitter<void>();
    constructor() {}

    onChange(): void {
        this.change.emit();
    }

    onAddressChange(): void {
        this.openAddressSelection.emit();
    }
}
