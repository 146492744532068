import {
    EWithdrawMoneyStatus,
    EWithdrawMoneyType,
} from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';

export interface AccountInfoTransactionItemHistoryItemModelData {
    amount: number;
    type: EWithdrawMoneyType;
    created: string;
    status?: EWithdrawMoneyStatus;
}

export interface AccountInfoTransactionItemHistoryItemModel extends AccountInfoTransactionItemHistoryItemModelData {}

export class AccountInfoTransactionItemHistoryItemModel {}
