import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppMainConfigService } from './app-main-config.service';
import { appMainConfigFactory, APP_MAIN_CONFIG } from './app-main.config';

@NgModule()
export class AppMainConfigModule {
    static forRoot(): ModuleWithProviders<AppMainConfigModule> {
        return {
            ngModule: AppMainConfigModule,
            providers: [
                AppMainConfigService,
                {
                    provide: APP_MAIN_CONFIG,
                    useFactory: appMainConfigFactory,
                },
            ],
        };
    }
}
