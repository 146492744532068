import { CreateFighterParams } from '@fca-app/api/fca/users/interfaces/params/create/create-fighter.params';
import {
    FighterExperience,
    FighterGender,
    FighterSearchDistance,
    FighterUnitSystem,
    FighterWeight,
} from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-api.response';
import { CreateUserBaseBuilder } from '@fca-app/services/params/builders/users/create/create-user-base.builder';
import { CreateFighterFormDataParams } from '@fca-app/services/params/users/create/create-fighter-form-data.params';

export class CreateFighterParamsBuilder extends CreateUserBaseBuilder {
    build(data: CreateFighterFormDataParams): CreateFighterParams {
        return {
            ...super.build(data),
            firstName: data.firstName,
            lastName: data.lastName,
            ...(data.nickname && {
                nickname: data.nickname,
            }),
            unitSystem: data.unitSystem as FighterUnitSystem,
            height: Number(data.height),
            weight: data.weight as FighterWeight,
            ...(data.coaches && {
                coaches: data.coaches,
            }),
            ...(data.club && {
                club: data.club,
            }),
            experience: data.experience as FighterExperience,
            residence: {
                ...(data.location.formattedAddress && {
                    formattedAddress: data.location.formattedAddress,
                }),
                country: data.location.fullAddress.address.country,
                city: data.location.fullAddress.address.city,
                ...(data.location.fullAddress.address.street && {
                    street: data.location.fullAddress.address.street,
                }),
                ...(data.location.fullAddress.address.building && {
                    building: data.location.fullAddress.address.building,
                }),
                latitude: Number(data.location.location.lat),
                longitude: Number(data.location.location.lng),
            },
            ...(data.searchDistance && {
                searchDistance: data.searchDistance as FighterSearchDistance,
            }),
            birthdate: new Date(data.birthdate),
            gender: data.gender as FighterGender,
        };
    }
}
