import { Injectable } from "@angular/core";
import { FcaMatchmakingApiService } from "@fca-app/api/fca/matchmaking/fca-matchmaking-api.service";
import {
  EMatchingStatus,
  FighterMatchmakingRequestApiResponse
} from "@fca-app/api/fca/matchmaking/interfaces/response/fighter-matchmaking-request-api.response";
import { IMatchmakingSearchRequest } from "@fca-app/dashboard/components/matchmaking/search/search-matchmaking.component";
import { MatchmakingRequestFactory } from "@fca-app/models/matchmaking/matchmaking-request.factory";
import { MatchmakingRequestMapper } from "@fca-app/models/matchmaking/matchmaking-request.mapper";
import { MatchmakingRequestModel } from "@fca-app/models/matchmaking/matchmaking-request.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class MatchmakingService {
  constructor(private readonly matchmakingService: FcaMatchmakingApiService) {}

  cancel(userId: number, fightEventId: string, status: EMatchingStatus): Observable<void> {
    return this.matchmakingService.cancelRequest(userId, fightEventId, status);
  }

  confirmMatch(fighterId: number, fightEventId: string): Observable<void> {
    return this.matchmakingService.confirmRequest(fighterId, fightEventId);
  }

  getFightEvents(name: string | null) {
    return this.matchmakingService.getFightEvents(name);
  }

  getMatchmakingList(data: IMatchmakingSearchRequest) {
    return this.matchmakingService.getMatchmakingList(data).pipe(
      map(rawData => this.mapRawData(rawData))
    );
  }

  getFighterRequests(fighterId: number): Observable<MatchmakingRequestModel[]> {
    return this.matchmakingService.getFighterRequests(fighterId).pipe(
      map(rawData => this.mapRawData(rawData))
    );
  }

  private mapRawData(rawData: FighterMatchmakingRequestApiResponse[]): MatchmakingRequestModel[] {
    return rawData.reduce<MatchmakingRequestModel[]>((acc, raw) => {
      try {
        const model = new MatchmakingRequestFactory().getModelFromData(
          new MatchmakingRequestMapper().mapData(raw)
        );
        acc.push(model);
      } catch(e) {
        console.error(e);
      }
      return acc;
    }, []);
  }
}
