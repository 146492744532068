import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { CreateNewUserAbstract } from '@fca-app/dashboard/components/users-management/create-new/create-new-user.abstract';
import { EFighterExperience } from '@fca-app/enums/fighter-experience.enum';
import { EFighterWeight } from '@fca-app/enums/fighter-weight.enum';
import { EUserGender } from '@fca-app/enums/gender.enum';
import { ESearchDistance } from '@fca-app/enums/search-distance.enum';
import { EUnitSystem } from '@fca-app/enums/unit-system.enum';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';
import { ELocale } from '@fca-app/shared/enums/locale.enum';

@Component({
    selector: 'users-create-fighter',
    templateUrl: './create-new-fighter.component.html',
    styleUrls: ['./create-new-fighter.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CreateNewFighterComponent extends CreateNewUserAbstract implements OnInit, OnDestroy {
    selectedUnitSystem: string | null = null;
    selectedLocale: string | null = null;
    selectedWeight: string | null = null;
    selectedExperience: string | null = null;
    selectedSearchDistance: string | null = null;
    selectedGender: string | null = null;
    isModalLocationVisible: boolean = false;
    selectedGeo: GeocodingModel | null;

    constructor(protected readonly parentFormDirective: FormGroupDirective) {
        super(parentFormDirective);

        this.selectedUnitSystem = EUnitSystem.METRIC;
        this.selectedLocale = ELocale.EN;
        this.selectedWeight = EFighterWeight.STRAWWEIGHT;
        this.selectedExperience = EFighterExperience.BEGINNER;
        // this.selectedSearchDistance = ESearchDistance.LOCAL;
        this.selectedGender = EUserGender.MALE;
    }

    public get unitSystems(): string[] {
        return Object.values(EUnitSystem) as string[];
    }

    public get locales(): string[] {
        return Object.values(ELocale) as string[];
    }

    public onKeyDownLocation(ev: Event): void {
        ev.preventDefault();
    }

    public get weights(): string[] {
        return Object.values(EFighterWeight) as string[];
    }

    public get experiences(): string[] {
        return Object.values(EFighterExperience) as string[];
    }

    public get searchDistances(): string[] {
        return Object.values(ESearchDistance) as string[];
    }

    public get genders(): string[] {
        return Object.values(EUserGender) as string[];
    }

    public onSelectUnitSystem(unitSystem: string): void {
        this.selectedUnitSystem = unitSystem;
        this.form.get('unitSystem')?.patchValue(unitSystem);
    }

    public onSelectLocale(locale: string): void {
        this.selectedLocale = locale;
        this.form.get('locale')?.patchValue(locale);
    }

    public onSelectWeight(weight: string): void {
        this.selectedWeight = weight;
        this.form.get('weight')?.patchValue(weight);
    }

    public onSelectExperience(experience: string): void {
        this.selectedExperience = experience;
        this.form.get('experience')?.patchValue(experience);
    }

    public onSelectSearchDistance(distance: string): void {
        this.selectedSearchDistance = distance;
        this.form.get('searchDistance')?.patchValue(distance);
    }

    public onAddressChange(data: GeocodingModel) {
        this.handleModalCancel();
        this.form.get('location')?.patchValue(data);
        this.selectedGeo = data;
    }

    public openLocationSelection(): void {
        this.isModalLocationVisible = true;
    }

    public handleModalCancel(): void {
        this.isModalLocationVisible = false;
    }

    public onSelectGender(gender: string): void {
        this.selectedGender = gender;
        this.form.get('gender')?.patchValue(gender);
    }

    public removeControls(): void {
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('nickname');
        this.form.removeControl('height');
        this.form.removeControl('birthdate');
        this.form.removeControl('unitSystem');
        this.form.removeControl('locale');
        this.form.removeControl('weight');
        this.form.removeControl('experience');
        this.form.removeControl('searchDistance');
        this.form.removeControl('gender');
        this.form.removeControl('coaches');
        this.form.removeControl('club');
        this.form.removeControl('location');
    }

    public addControls(): void {
        this.form.addControl('firstName', new FormControl('', Validators.required));
        this.form.addControl('lastName', new FormControl('', Validators.required));
        this.form.addControl('nickname', new FormControl(''));
        this.form.addControl('height', new FormControl(0, Validators.required));
        this.form.addControl('birthdate', new FormControl('', Validators.required));
        this.form.addControl('unitSystem', new FormControl(this.selectedUnitSystem, Validators.required));
        this.form.addControl('locale', new FormControl(this.selectedLocale, Validators.required));
        this.form.addControl('weight', new FormControl(this.selectedWeight, Validators.required));
        this.form.addControl('experience', new FormControl(this.selectedExperience, Validators.required));
        this.form.addControl('searchDistance', new FormControl(this.selectedSearchDistance));
        this.form.addControl('gender', new FormControl(this.selectedGender, Validators.required));
        this.form.addControl('coaches', new FormControl(''));
        this.form.addControl('club', new FormControl(''));
        this.form.addControl('location', new FormControl(''));
    }

    ngOnInit(): void {
        this.addControls();
    }

    ngOnDestroy(): void {
        this.removeControls();
    }
}
