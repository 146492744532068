import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { HistoryVideoMetricsFactory } from '@fca-app/models/video-metrics/history/history-video-metrics.factory';
import { HistoryVideoMetricsModel } from '@fca-app/models/video-metrics/history/history-video-metrics.model';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'basic-statistics-video',
    templateUrl: './basic-statistics-video.component.html',
    styleUrls: ['./basic-statistics-video.component.scss'],
})
export class BasicStatisticsVideoComponent implements OnInit {
    @Input() eventBus = new EventEmitter<{ date: number[]; timeStepSelected: string; videoId: string | null }>();

    public historyMetrics$ = new BehaviorSubject<HistoryVideoMetricsModel>(
        new HistoryVideoMetricsFactory().getEmptyModel()
    );

    constructor(private readonly videoMetricsService: VideoMetricsService) {}

    ngOnInit(): void {
        this.eventBus
            .pipe(
                untilDestroy(this),
                mergeMap(data => this.videoMetricsService.getHistoryMetrics(data).pipe(take(1)))
            )
            .subscribe(data => {
                this.historyMetrics$.next(data);
            });
    }
}
