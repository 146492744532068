import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { CreateNewUserAbstract } from '@fca-app/dashboard/components/users-management/create-new/create-new-user.abstract';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';

@Component({
    selector: 'users-create-location-provider',
    templateUrl: './create-new-location-provider.component.html',
    styleUrls: ['./create-new-location-provider.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CreateNewLocationProviderComponent extends CreateNewUserAbstract implements OnInit, OnDestroy {
    isModalLocationVisible: boolean = false;
    selectedGeo: GeocodingModel | null;

    constructor(protected readonly parentFormDirective: FormGroupDirective) {
        super(parentFormDirective);
    }

    public addControls(): void {
        this.form.addControl('firstName', new FormControl('', Validators.required));
        this.form.addControl('lastName', new FormControl('', Validators.required));
        this.form.addControl('name', new FormControl('', Validators.required));
        this.form.addControl('link', new FormControl(''));
        this.form.addControl('description', new FormControl(''));
        this.form.addControl('location', new FormControl('', Validators.required));
    }

    public removeControls(): void {
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('name');
        this.form.removeControl('link');
        this.form.removeControl('description');
        this.form.removeControl('location');
    }

    ngOnDestroy(): void {
        this.removeControls();
    }

    ngOnInit(): void {
        this.addControls();
    }

    public onKeyDownLocation(ev: Event): void {
        ev.preventDefault();
    }

    public openLocationSelection(): void {
        this.isModalLocationVisible = true;
    }

    public handleModalCancel(): void {
        this.isModalLocationVisible = false;
    }

    public onAddressChange(data: GeocodingModel) {
        this.handleModalCancel();
        this.form.get('location')?.patchValue(data);
        this.selectedGeo = data;
    }
}
