import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@fca-app/auth/services/auth.service';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaStoreService } from '@fca-app/store/store.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
    userEmail$: Observable<string>;

    constructor(
        private readonly authService: AuthService,
        private readonly storeService: FcaStoreService,
        private router: Router
    ) {
        this.userEmail$ = this.storeService.getAuthData().pipe(map(data => data?.email || ''));
    }

    onLogout() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
}
